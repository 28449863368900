const yellow = {
	50: "#d8ffff",
	100: "#acfaff",
	200: "#7df5ff",
	300: "#4df1ff",
	400: "#27edfe",
	500: "#B8935C",
	600: "#00a4b3",
	700: "#007581",
	800: "#00474f",
	900: "#00191e",
};

const black = {
	50: "#F2F2F2",
	100: "#DBDBDB",
	200: "#C4C4C4",
	300: "#ADADAD",
	400: "#969696",
	500: "#000000",
	600: "#666666",
	700: "#4D4D4D",
	800: "#333333",
	900: "#000000",
};

export const colors = {
	yellow,
	black,
	primary: black,
};
