import "moment/locale/pt-br";
import LocalizedStrings from "localized-strings";

const strings = new LocalizedStrings({
	ptBR: {
		removeCharactersExceptLetterNumber: (character: string) => character.replace(/[^a-zA-Z0-9]/g, ""),
		enum: {
			StateUF: {
				AC: "Acre",
				AL: "Alagoas",
				AP: "Amapá",
				AM: "Amazonas",
				BA: "Bahia",
				CE: "Ceará",
				DF: "Distrito Federal",
				ES: "Espírito Santo",
				GO: "Goiás",
				MA: "Maranhão",
				MT: "Mato Grosso",
				MS: "Mato Grosso do Sul",
				MG: "Minas Gerais",
				PA: "Pará",
				PB: "Paraíba",
				PR: "Paraná",
				PE: "Pernambuco",
				PI: "Piauí",
				RJ: "Rio de Janeiro",
				RN: "Rio Grande do Norte",
				RS: "Rio Grande do Sul",
				RO: "Rondônia",
				RR: "Roraima",
				SC: "Santa Catarina",
				SP: "São Paulo",
				SE: "Sergipe",
				TO: "Tocantins",
			},
			ConsumptionStatus: {
				accepted: "Aceito",
				pending: "Pendente",
			},
			BankAccountType: {
				contaCorrente: "Conta Corrente",
				contaCorrenteConjunta: "Conta Corrente Conjunta",
				contaPoupanca: "Conta Poupança",
				contaPoupancaConjunta: "Conta Poupança Conjunta",
			},

		},
		components: {
			autoCompleteList: {
				empty: "Não há itens na lista",
			},
			autoCompleteModal: {
				back: "Voltar",
			},
			editProfile: {
				success: "Editado com sucesso",
			},
			imagePicker: {
				selectAnImage: "Selecione uma imagem",
			},
			checkinPeriodFilter: {
				filterDate: "Filtrar por data de criação",
			},
			consumeCounter: {
				title: "Insira a quantidade a ser consumida",
			},
			addressForm: {
				title: "Endereço:",
				zipcode: "CEP:",
				street: "Rua:",
				neighborhood: "Bairro:",
				number: "Número:",
				complement: "Complemento:",
				addressPlaceholder: "Digite um endereço...",
				city: "Cidade:",
				countryCode: "Código do País:",
				state: "Estado:",
			},
			detailsBalance: {
				consumeDoses: "Consumir doses",
			},
			cpfFilter: {
				placeholder: "Digite um cpf...",
			},
			moneyInput: {
				coin: "R$",
			},
		},
		nav: {
			home: "Home",
			restaurants: "Restaurantes",
			adminUsers: "Usuários Adminstrativos",
			restaurantProfile: "Perfil do Restaurante",
			products: "Produtos",
			purchasedProducts: "Produtos Comprados",
			users: "Usuários",
			adminRestaurantUsers: "Usuários Restaurantes",
			consumptions: "Consumos",
			wallet: "Meus ganhos",
			logout: "Sair",
		},
		actions: {
			save: "Salvar",
			delete: "Apagar",
			create: "Criar",
			cancel: "Cancelar",
			select: "Selecionar",
			back: "Voltar",
			add: "Adicionar",
			send: "Enviar",
			search: "Buscar",
		},
		moment: {
			day: "DD",
			dateMonth: "DD/MM",
			date: "DD/MM/YYYY",
			dateHour: "DD/MM/YYYY HH[h]",
			dateTime: "DD/MM/YYYY HH:mm",
		},
		error: {
			stillLoading: "Ainda Carregando, aguarde.",
			notLoggedIn: "Usuario não logado",
			default: "Houve um erro, tente novamente mais tarde.",
			missingRestaurant: "É preciso selecionar o restaurante dono do produto",
			currentUserIsNotSuperAdmin: "Sem Permissão para acessar rota. Não é super admin",
			currentUserIsNotRestaurantAdmin: "Sem Permissão para acessar rota. Não é um admin restaurante",
		},
		common: {
			noResutls: "Sem Resultados",
			delete: "Clique para deletar",
			disable: "Clique para desabilitar",
			enable: "Clique para habilitar",
			details: "Clique para ver mais informações",
			edit: "Clique para editar",
			refuseToolTip: "Clique para recusar",
			approveToolTip: "Clique para aprovar",
			cancel: "Cancelar",
			approve: "Aprovar",
			refuse: "Recusar",
			toDelete: "Deletar",
			finish: "Finalizar",
			confirm: "Confirmar",
			detailsTitle: "Detalhes",
			editTitle: "Editar",
			send: "Enviar",
			buttons: {
				confirmButton: (isEdit?: boolean) => isEdit ? "Editar" : "Enviar",
				backButton: "Voltar",
			},
			empty: "Vazio",
			fields: {
				link: "Link:",
				name: "Nome:",
				corporateName: "Nome corporativo:",
				password: "Senha:",
				email: "E-mail:",
				photo: "Foto:",
				phone: "Celular:",
				documentNumber: "CNPJ: ",
				cpf:"CPF: ",
				zipcode: "CEP:",
				street: "Rua:",
				streetNumber: "Número:",
				complementary: "Complemento:",
				neighborhood: "Bairro:",
				city: "Cidade:",
				countryCode: "Código do País:",
				state: "Estado:",
				totalAmountOfDoses: "Total de Doses:",
				price: "Preço:",
				title: "Nome:",
				description: "Descrição:",
				createdAt: "Data de Criação:",
				purchasedAt: "Data de Compra:",
				restaurant: "Restaurante:",
				consumedDoses: "Doses Consumidas:",
				availableDoses: "Doses Disponíveis:",
				statusDoses: "Situação das Doses:",
				amount: "Quantidade",
				approvedAt: "Aprovado em:",
				requestedAt: "Solicitação criada em:",
				status: "Status:",
				bankAccount: "Conta:",
				bankSelect: "Clique e selecione o seu banco",
				typeAccount: "Tipo da Conta:",
				bankCode: "Código do Banco",
				accountDv: "Dígito da conta:",
				bank: "Banco:",
				agency: "Agência:",
				agencyDigit: "Dígito da Agência:",
				accountNumber: "Número da Conta:",
				accountDigit: "Dígito da Conta:",
				cnpj: "CNPJ:",
				bankName: "Nome do Banco:",
				concatData: (left: string, right: string | null) =>
					right ? `${left} - ${right}` : left,
			},
			mask: {
				date: "__/__/____",
				time: "__:__",
			},
			modal: {
				title: "Deletar",
				description: (itemName: string) => `Tem certeza que deseja deletar ${itemName}?`,
				descriptionRemove: (itemName: string) => `Tem certeza que deseja remover ${itemName}?`,
				button: {
					remove: "Remover",
					delete: "Deletar",
					cancel: "Cancelar",
				},
			},
		},
		home: {
			message: (userName: string) => `Bem vindo ao Dashboard,  ${userName}!`,
		},
		login: {
			loginIn: "Login",
			recoveryPassword: "Recuperar Senha",
			success: (userName: string) => `Bem vindo  ${userName}.`,
			fields: {
				email: "E-mail",
				password: "Senha",
			},
		},
		recoveryPage: {
			success: "Bem vindo!",
			title: "Recuperação de Senha",
			newAccess: "Novo Acesso",
			sendSuccess: "E-mail enviado com sucesso!",
			recoverPasswordButton: "Enviar e-mail",
			newPasswordButton: "Alterar Senha",
			email: "Email",
			token: "Token",
			validToken: "Token válido!",
			confirmTokenButton: "Confirmar Token",
			tokenButton: "Já possui um token?",
			noTokenButton: "Não possuo token",
			newPassword: "Nova senha",
			confirmNewPassword: "Confirme sua nova senha",
			samePasswordError: "As senhas não correspondem",
		},
		users: {
			table: {
				title: "Lista de Usuários",
				header: ["", "Usuários", "Email", "Data de Cadastro"],
				delete: (userName: string) => `Usuário ${userName} foi deletado!`,
				statusUser: (isUserBlocked: boolean) => `O Usuário ${isUserBlocked ? "foi bloqueado" : "foi desbloaqueado"}`,
				totalText: "Usuários cadastrados",
			},
			edit: {
				title: "Edição de Usuário",
				success: "Usuário editado com sucesso",
			},
			details: {
				title: "Detalhes",
			},
		},
		restaurants: {
			autocompleteLabel: "Restaurante:",
			autocompletePlaceholder: "Pesquisar restaurantes",
			totalText: "Restaurantes cadastrados",
			table: {
				title: "Lista Usuários do Restaurante",
				header: ["", "Nome do Restaurante", "Documento do Restaurante", "Data de Cadastro"],
				delete: (userName: string) => `Restaurante ${userName} foi deletado!`,
				statusRestaurant: (isUserBlocked: boolean) => `O Restaurante ${isUserBlocked ? "foi bloqueado" : "foi desbloqueado"}`,
				addButtonText: "Adicionar novo Restaurante",
				totalText: "Restaurantes cadastrados",
			},
			createOrEdit: {
				title: (isEdit?: boolean) => isEdit ? "Edição de Restaurante" : "Cadastro de Restaurante",
				success: (isEdit?: boolean) => isEdit ? "Restaurante editado com sucesso" : "Restaurante criado com sucesso",
				address: "Endereço",
				helperServiceDays: "(dias da semana e horário):",
				errorAddress: "Erro no preenchimento do endereço.",
				documents: [
					"Contrato Social",
					"CNPJ Atualizado",
					"CCM ou ISS junto à prefeitura",
					"CNES",
					"Comprovante do pagamento do ISS",
					"Comprovante do pagamento da taxa de fiscalização do estabelecimento",
					"Alvará de funcionamento e vigilância sanitária atualizados",
					"Currículo completo de todos os profissionais que trabalham na restaurante (com o título de especialista)",
					"Comprovante de conta bancária",
				],
				documentsTitle: "Documentos",
				bankAccountTitle: (hasBankAccount: boolean) => hasBankAccount ? "Editar Conta Bancária" : "Cadastro de Conta",
				banckAccount: "Conta Bancária:",
				successBankAccount: (isEdit?: boolean) => isEdit ? "Conta editada com sucesso" : "Conta Bancária criada com sucesso",
				banksCode: {
					code1 :{
						name: "Banco do Brasil S.A.",
						code: "001",
					},
					code2 :{
						name: "Banco Itaú S.A.",
						code: "341",
					},
					code3 :{
						name: "Banco Santander (Brasil) S.A.",
						code: "033",
					},
					code4 :{
						name: "Banco Real S.A.",
						code: "356",
					},
					code5 :{
						name: "Itaú Unibanco Holding S.A.",
						code: "652",
					},
					code6 :{
						name: "Banco Bradesco S.A.",
						code: "237",
					},
					code7 :{
						name: "Banco Citibank S.A.",
						code: "745",
					},
					code8 :{
						name: "HSBC Bank Brasil S.A. – Banco Múltiplo",
						code: "399",
					},
					code9 :{
						name: "Caixa Econômica Federal",
						code: "104",
					},
					code10 :{
						name: "Banco Mercantil do Brasil S.A.",
						code: "389",
					},
					code11 :{
						name: "Nubank S.A.",
						code: "260",
					},
					code12 :{
						name: "Banco Inter",
						code: "077",
					},
					code13 :{
						name: "Neon Pagamentos S.A",
						code: "735",
					},
					code14 :{
						name: "Digio",
						code: "335",
					},
					code15 :{
						name: "Next",
						code: "237",
					},
					code16 :{
						name: "Banco PAN",
						code: "623",
					},
					code17 :{
						name: "C6 Bank",
						code: "336",
					},
				},
			},
			modal: {
				title: (isRestaurantBlocked: boolean) => ` ${isRestaurantBlocked ? "Habilitar" : "Desabilitar"} Restaurante`,
				description: (itemName: string, isRestaurantBlocked: boolean) => `Tem certeza que deseja ${isRestaurantBlocked ? "habilitar" : "desabilitar"} ${itemName}?`,
				button: {
					disable: "Desabilitar",
					enable: "Habilitar",
					cancel: "Cancelar",
				},
			},
			adminUsersTable: {
				title: "Lista de Usuários Admin do Restaurante:",
				header: ["", "Usuários Admin", "Email"],
				delete: (userName: string) => `Usuário ${userName} foi deletado!`,
				addButtonText: "Adicionar novo usuário admin",
			},
		},
		products: {
			title: "Products",
			table: {
				title: "Tabela de Produtos",
				header: ["", "Nome do Produto", "Preço", "Total de Doses", "Data de Cadastro"],
				delete: (name: string) => `Produto ${name} foi deletado!`,
				statusDisable: (isBlocked: boolean) => `O Produto ${isBlocked ? "foi disabilitado" : "foi habilitado"}`,
				addButtonText: "Adicionar novo Produto",
			},
			createOrEdit: {
				title: (isEdit?: boolean) => isEdit ? "Edição de Produto" : "Cadastro de Produto",
				button: (isEdit?: boolean) => isEdit ? "Editar" : "Cadastrar",
				searchRestaurant: "Buscar restaurante...",
			},
			success: (isEdit?: boolean) => isEdit ? "Produto editado com sucesso" : "Produto criado com sucesso",
			modal: {
				title: (isDisabled: boolean) => ` ${isDisabled ? "Habilitar" : "Desabilitar"} Produto`,
				description: (itemName: string, isDisabled: boolean) => `Tem certeza que deseja ${isDisabled ? "habilitar" : "desabilitar"} ${itemName}?`,
				button: {
					disable: "Desabilitar",
					enable: "Habilitar",
					cancel: "Cancelar",
				},
			},
		},
		adminUsers: {
			table: {
				title: "Lista de Usuários Admin",
				header: ["", "Usuários Admin", "Email"],
				delete: (userName: string) => `Usuário ${userName} foi deletado!`,
				addButtonText: "Adicionar novo usuário admin",
			},
			createOrEdit: {
				title: (isEdit?: boolean) => isEdit ? "Edição de Usuário Admin" : "Cadastro de Usuário Admin",
				titleRestaurantUser: (isEdit?: boolean) => isEdit ? "Edição de Usuário Restaurante" : "Cadastro de Usuário Restaurante",
				success: (isEdit?: boolean) => isEdit ? "Usuário editado com sucesso" : "Usuário criado com sucesso",
			},
		},
		adminRestaurantUsers: {
			autocompleteLabel: "Restaurante:",
			autocompletePlaceholder: "Pesquisar Restaurantes",
			table: {
				title: "Lista Usuários Admins do Restaurante",
				header: (isAdminRestaurantUser: boolean) => isAdminRestaurantUser ? ["", "Usuários Admins do Restaurante", "Data de Cadastro"] : ["", "Usuários Admins do Restaurante", "Restaurante", "Data de Cadastro"],
				tableHeaders: ["", "Usuários admins do Restaurante"],
				tableTitle: "Usuários Restaurante",
				tableAddButton: "Adicionar novo usuário",
			},
			createOrEdit: {
				title: (isEdit?: boolean) => isEdit ? "Edição de Usuário Admin" : "Cadastro de Usuário Admin",
				titleRestaurantUser: (isEdit?: boolean) => isEdit ? "Edição de Usuário Restaurante" : "Cadastro de Usuário Restaurante",
				success: (isEdit?: boolean) => isEdit ? "Usuário editado com sucesso" : "Usuário criado com sucesso",
			},
		},
		purchasedProducts: {
			table: {
				title: "Lista Produtos Comprados",
				header: ["", "Nome do Produto", "Preço", "Doses Consumidas", "Nome do Usuário", "Email", "Data da Compra"],
			},
			details: {
				titleProduct: "Detalhes do Produto",
				titleUser: "Detalhes do Usuário",
				titleBalance: "Saldo do Usuário",
				successMessage:(amout: number)=> `${amout} doses foram consumidas`,
			},
			consumptionHistory: {
				title: "Histórico de Consumo",
				header: ["Quantidade", "Status", "Data de Validação"],
				notValidated: "Não validado",
			},
		},
		consumptions: {
			pendingTable: {
				title: "Solicitações de Consumo",
				header: ["", "Produto", "Quantidade Consumida", "Data da Solicitação", "Usuário"],
				successDelete: "Consumo deletado com sucesso",
				deleteModal: {
					title: "Recusar solicitação de consumo",
					description: "Tem certeza que deseja Recusar essa solicitação de consumo?",
					button: {
						delete: "Deletar",
						cancel: "Cancelar",
					},
				},
				approveModal: {
					title: "Aprovar Consumo",
					description: "Tem certeza que deseja aprovar essa solicitação de consumo?",
				},
			},
			table: {
				title: "Consumos Aprovados",
				header: ["", "Produto","Quantidade Consumida", "Data da Solicitação", "data de aprovação","Usuário"],
			},
		},
		purchasedProductsByUser: {
			table: {
				title: "Lista Produtos Comprados pelo Usuário",
				header: ["", "Nome do Produto", "Total de Doses", "Doses Disponíveis", "Doses Consumidas", "Data da Compra"],
			},
		},
		restaurantProfile: {
			title: "Perfil do Restaurante",
			bankAccountTitle: (hasBankAccount: boolean) => hasBankAccount ? "Editar Conta Bancária" : "Cadastro de Conta",
		},
		wallet: {
			withdrawButton: "Sacar",
			title: "Saldo",
			bankAccountTitle: "Conta Bancária",
			transferred: "Transferido",
			pending: "Pendente",
			available: "Disponível",
			success: "Saldo retirado",
			withdrawError: "Erro ao tentar retirar saldo",
			modalHeader: "Informe o valor que deseja retirar. A transação está sujeita a uma taxa de R$ 3,67.",
		},
	},
});

export default strings;
