import { LocalizedStringsMethods } from "localized-strings";
import { UAParser } from "ua-parser-js";

let baseUrl: string = "localhost:8000";
let strings: (LocalizedStringsMethods & any) | null = null;

export function setUrl(url: string): void {
    baseUrl = url;
}

export function setStrings(newStrings: (LocalizedStringsMethods & {}) | null): void {
    strings = newStrings;
}
export interface Phone {
    ddd: string;
    number: string;
}

export interface Image {
    thumb: SimpleImage;
    width: number;
    height: number;
    url: string;
}

export interface SimpleImage {
    width: number;
    height: number;
    url: string;
}

export interface Address {
    zipcode: string;
    street: string;
    streetNumber: string;
    complementary: string | null;
    neighborhood: string;
    city: string;
    state: StateUF;
    countryCode: string;
}

export interface TEMPNewCreditCard {
    expirationMonth: string;
    expirationYear: string;
    number: string;
    cvv: string;
}

export interface CreditCard {
    id: string;
    expirationMonth: string;
    expirationYear: string;
    lastFourDigits: string;
}

export interface PagarmeAddress {
    zipcode: string;
    street: string;
    streetNumber: string;
    neighborhood: string;
    complementary: string | null;
}

export interface Transaction {
    id: string;
    boletoUrl: string | null;
    boletoBarcode: string | null;
    boletoExpirationDate: string | null;
    paymentMethod: PaymentMethods;
    status: TransactionStatus;
    statusReason: string;
    metadata: string;
    pixQRCode: string | null;
    qrExpirationDate: string | null;
    createdAt: Date;
    amount: number;
    lastFourDigits: string | null;
    cardBrand: string | null;
}

export interface UncertainImage {
    bytes: Buffer | null;
    image: Image | null;
}

export interface UncertainFile {
    bytes: Buffer | null;
    url: string | null;
}

export interface NewBankAccount {
    bankName: string;
    bankCode: string;
    agency: string;
    agencyDv: string | null;
    account: string;
    accountDv: string | null;
    type: BankAccountType;
    documentNumber: string;
}

export interface BankAccount {
    id: string;
    bankName: string;
    bankCode: string;
    agency: string;
    agencyDv: string | null;
    account: string;
    accountDv: string | null;
    type: BankAccountType;
    documentNumber: string;
}

export interface Restaurant {
    id: string;
    name: string;
    corporateName: string;
    documentNumber: string;
    address: Address;
    createdAt: Date | null;
    bankAccount: BankAccount | null;
    blockedAt: Date | null;
    image: Image | null;
}

export interface EditRestaurant {
    name: string;
    corporateName: string;
    documentNumber: string;
    address: Address;
    image: UncertainImage | null;
}

export interface NewRestaurant {
    name: string;
    corporateName: string;
    documentNumber: string;
    address: Address;
    image: UncertainImage | null;
}

export interface EditRestaurantForRestaurantAdmin {
    name: string;
    corporateName: string;
    address: Address;
    image: UncertainImage | null;
}

export interface Balance {
    waiting: number;
    available: number;
    transferred: number;
}

export interface AdminUser {
    id: string;
    restaurant: Restaurant | null;
    createdAt: Date;
    name: string;
    email: string;
}

export interface EditAdminUser {
    name: string;
    email: string;
}

export interface NewAdminUser {
    restaurantId: string | null;
    password: string;
    name: string;
    email: string;
}

export interface User {
    id: string;
    email: string;
    name: string;
    phone: string;
    birthdate: Date | null;
    documentNumber: string | null;
    address: Address | null;
    image: Image | null;
    createdAt: Date;
}

export interface UserInfo {
    id: string;
    email: string;
    name: string;
    phone: string;
    documentNumber: string | null;
}

export interface EditUser {
    email: string;
    name: string;
    birthdate: Date | null;
    phone: string;
    address: Address | null;
    image: UncertainImage | null;
    documentNumber: string | null;
}

export interface ProductConsumed {
    id: string;
    title: string;
}

export interface PurchasedProductConsumed {
    id: string;
    product: ProductConsumed;
}

export interface Consumption {
    id: string;
    amount: number;
    createdAt: Date;
    acceptedAt: Date | null;
}

export interface ConsumptionDetailed {
    user: UserInfo;
    purchasedProduct: PurchasedProductConsumed;
    id: string;
    amount: number;
    createdAt: Date;
    acceptedAt: Date | null;
}

export interface ProductDetails {
    title: string;
    description: string;
    price: number;
    totalNumberOfDoses: number;
}

export interface NewProduct {
    image: UncertainImage | null;
    title: string;
    description: string;
    price: number;
    totalNumberOfDoses: number;
}

export interface Product {
    id: string;
    image: Image | null;
    createdAt: Date;
    disabledAt: Date | null;
    title: string;
    description: string;
    price: number;
    totalNumberOfDoses: number;
}

export interface PurchasedProductInfo {
    id: string;
    paymentMethod: PaymentMethods;
    status: ProductStatus;
    purchasedAt: Date;
    consumedDoses: number;
    totalDoses: number;
}

export interface PurchasedProduct {
    paymentMethod: PaymentMethods;
    product: Product;
    user: User;
    id: string;
    status: ProductStatus;
    purchasedAt: Date;
    consumedDoses: number;
    totalDoses: number;
}

export interface PurchasedProductWithoutUser {
    product: Product;
    id: string;
    paymentMethod: PaymentMethods;
    status: ProductStatus;
    purchasedAt: Date;
    consumedDoses: number;
    totalDoses: number;
}

export interface UserFilterOptions {
    name: string | null;
    documentNumber: string | null;
}

export enum PaymentMethods {
    creditCard = "creditCard",
    boleto = "boleto",
    pix = "pix",
}

export function translatePaymentMethods(enumPaymentMethods: PaymentMethods): string {
    switch (enumPaymentMethods) {
        case PaymentMethods.creditCard: {
            return strings ? strings["enum"]["PaymentMethods"]["creditCard"] || PaymentMethods.creditCard : PaymentMethods.creditCard;
        }
        case PaymentMethods.boleto: {
            return strings ? strings["enum"]["PaymentMethods"]["boleto"] || PaymentMethods.boleto : PaymentMethods.boleto;
        }
        case PaymentMethods.pix: {
            return strings ? strings["enum"]["PaymentMethods"]["pix"] || PaymentMethods.pix : PaymentMethods.pix;
        }
    }
    return "";
}

export function allValuesPaymentMethods(): PaymentMethods[] {
    return [
        PaymentMethods.creditCard,
        PaymentMethods.boleto,
        PaymentMethods.pix,
    ];
}

export function allTranslatedValuesPaymentMethods(): string[] {
    return [
        translatePaymentMethods(PaymentMethods.creditCard),
        translatePaymentMethods(PaymentMethods.boleto),
        translatePaymentMethods(PaymentMethods.pix),
    ];
}

export function allDisplayableValuesPaymentMethods(): string[] {
    return allTranslatedValuesPaymentMethods().sort();
}

export function valueFromTranslationPaymentMethods(translation: string): PaymentMethods {
    const index = allTranslatedValuesPaymentMethods().indexOf(translation);
    return allValuesPaymentMethods()[index] || PaymentMethods.creditCard;
}

export enum ProductStatus {
    active = "active",
    inactive = "inactive",
}

export function translateProductStatus(enumProductStatus: ProductStatus): string {
    switch (enumProductStatus) {
        case ProductStatus.active: {
            return strings ? strings["enum"]["ProductStatus"]["active"] || ProductStatus.active : ProductStatus.active;
        }
        case ProductStatus.inactive: {
            return strings ? strings["enum"]["ProductStatus"]["inactive"] || ProductStatus.inactive : ProductStatus.inactive;
        }
    }
    return "";
}

export function allValuesProductStatus(): ProductStatus[] {
    return [
        ProductStatus.active,
        ProductStatus.inactive,
    ];
}

export function allTranslatedValuesProductStatus(): string[] {
    return [
        translateProductStatus(ProductStatus.active),
        translateProductStatus(ProductStatus.inactive),
    ];
}

export function allDisplayableValuesProductStatus(): string[] {
    return allTranslatedValuesProductStatus().sort();
}

export function valueFromTranslationProductStatus(translation: string): ProductStatus {
    const index = allTranslatedValuesProductStatus().indexOf(translation);
    return allValuesProductStatus()[index] || ProductStatus.active;
}

export enum StateUF {
    AC = "AC",
    AL = "AL",
    AP = "AP",
    AM = "AM",
    BA = "BA",
    CE = "CE",
    DF = "DF",
    ES = "ES",
    GO = "GO",
    MA = "MA",
    MT = "MT",
    MS = "MS",
    MG = "MG",
    PA = "PA",
    PB = "PB",
    PR = "PR",
    PE = "PE",
    PI = "PI",
    RJ = "RJ",
    RN = "RN",
    RS = "RS",
    RO = "RO",
    RR = "RR",
    SC = "SC",
    SP = "SP",
    SE = "SE",
    TO = "TO",
}

export function translateStateUF(enumStateUF: StateUF): string {
    switch (enumStateUF) {
        case StateUF.AC: {
            return strings ? strings["enum"]["StateUF"]["AC"] || StateUF.AC : StateUF.AC;
        }
        case StateUF.AL: {
            return strings ? strings["enum"]["StateUF"]["AL"] || StateUF.AL : StateUF.AL;
        }
        case StateUF.AP: {
            return strings ? strings["enum"]["StateUF"]["AP"] || StateUF.AP : StateUF.AP;
        }
        case StateUF.AM: {
            return strings ? strings["enum"]["StateUF"]["AM"] || StateUF.AM : StateUF.AM;
        }
        case StateUF.BA: {
            return strings ? strings["enum"]["StateUF"]["BA"] || StateUF.BA : StateUF.BA;
        }
        case StateUF.CE: {
            return strings ? strings["enum"]["StateUF"]["CE"] || StateUF.CE : StateUF.CE;
        }
        case StateUF.DF: {
            return strings ? strings["enum"]["StateUF"]["DF"] || StateUF.DF : StateUF.DF;
        }
        case StateUF.ES: {
            return strings ? strings["enum"]["StateUF"]["ES"] || StateUF.ES : StateUF.ES;
        }
        case StateUF.GO: {
            return strings ? strings["enum"]["StateUF"]["GO"] || StateUF.GO : StateUF.GO;
        }
        case StateUF.MA: {
            return strings ? strings["enum"]["StateUF"]["MA"] || StateUF.MA : StateUF.MA;
        }
        case StateUF.MT: {
            return strings ? strings["enum"]["StateUF"]["MT"] || StateUF.MT : StateUF.MT;
        }
        case StateUF.MS: {
            return strings ? strings["enum"]["StateUF"]["MS"] || StateUF.MS : StateUF.MS;
        }
        case StateUF.MG: {
            return strings ? strings["enum"]["StateUF"]["MG"] || StateUF.MG : StateUF.MG;
        }
        case StateUF.PA: {
            return strings ? strings["enum"]["StateUF"]["PA"] || StateUF.PA : StateUF.PA;
        }
        case StateUF.PB: {
            return strings ? strings["enum"]["StateUF"]["PB"] || StateUF.PB : StateUF.PB;
        }
        case StateUF.PR: {
            return strings ? strings["enum"]["StateUF"]["PR"] || StateUF.PR : StateUF.PR;
        }
        case StateUF.PE: {
            return strings ? strings["enum"]["StateUF"]["PE"] || StateUF.PE : StateUF.PE;
        }
        case StateUF.PI: {
            return strings ? strings["enum"]["StateUF"]["PI"] || StateUF.PI : StateUF.PI;
        }
        case StateUF.RJ: {
            return strings ? strings["enum"]["StateUF"]["RJ"] || StateUF.RJ : StateUF.RJ;
        }
        case StateUF.RN: {
            return strings ? strings["enum"]["StateUF"]["RN"] || StateUF.RN : StateUF.RN;
        }
        case StateUF.RS: {
            return strings ? strings["enum"]["StateUF"]["RS"] || StateUF.RS : StateUF.RS;
        }
        case StateUF.RO: {
            return strings ? strings["enum"]["StateUF"]["RO"] || StateUF.RO : StateUF.RO;
        }
        case StateUF.RR: {
            return strings ? strings["enum"]["StateUF"]["RR"] || StateUF.RR : StateUF.RR;
        }
        case StateUF.SC: {
            return strings ? strings["enum"]["StateUF"]["SC"] || StateUF.SC : StateUF.SC;
        }
        case StateUF.SP: {
            return strings ? strings["enum"]["StateUF"]["SP"] || StateUF.SP : StateUF.SP;
        }
        case StateUF.SE: {
            return strings ? strings["enum"]["StateUF"]["SE"] || StateUF.SE : StateUF.SE;
        }
        case StateUF.TO: {
            return strings ? strings["enum"]["StateUF"]["TO"] || StateUF.TO : StateUF.TO;
        }
    }
    return "";
}

export function allValuesStateUF(): StateUF[] {
    return [
        StateUF.AC,
        StateUF.AL,
        StateUF.AP,
        StateUF.AM,
        StateUF.BA,
        StateUF.CE,
        StateUF.DF,
        StateUF.ES,
        StateUF.GO,
        StateUF.MA,
        StateUF.MT,
        StateUF.MS,
        StateUF.MG,
        StateUF.PA,
        StateUF.PB,
        StateUF.PR,
        StateUF.PE,
        StateUF.PI,
        StateUF.RJ,
        StateUF.RN,
        StateUF.RS,
        StateUF.RO,
        StateUF.RR,
        StateUF.SC,
        StateUF.SP,
        StateUF.SE,
        StateUF.TO,
    ];
}

export function allTranslatedValuesStateUF(): string[] {
    return [
        translateStateUF(StateUF.AC),
        translateStateUF(StateUF.AL),
        translateStateUF(StateUF.AP),
        translateStateUF(StateUF.AM),
        translateStateUF(StateUF.BA),
        translateStateUF(StateUF.CE),
        translateStateUF(StateUF.DF),
        translateStateUF(StateUF.ES),
        translateStateUF(StateUF.GO),
        translateStateUF(StateUF.MA),
        translateStateUF(StateUF.MT),
        translateStateUF(StateUF.MS),
        translateStateUF(StateUF.MG),
        translateStateUF(StateUF.PA),
        translateStateUF(StateUF.PB),
        translateStateUF(StateUF.PR),
        translateStateUF(StateUF.PE),
        translateStateUF(StateUF.PI),
        translateStateUF(StateUF.RJ),
        translateStateUF(StateUF.RN),
        translateStateUF(StateUF.RS),
        translateStateUF(StateUF.RO),
        translateStateUF(StateUF.RR),
        translateStateUF(StateUF.SC),
        translateStateUF(StateUF.SP),
        translateStateUF(StateUF.SE),
        translateStateUF(StateUF.TO),
    ];
}

export function allDisplayableValuesStateUF(): string[] {
    return allTranslatedValuesStateUF().sort();
}

export function valueFromTranslationStateUF(translation: string): StateUF {
    const index = allTranslatedValuesStateUF().indexOf(translation);
    return allValuesStateUF()[index] || StateUF.AC;
}

export enum BankAccountType {
    contaCorrente = "contaCorrente",
    contaPoupanca = "contaPoupanca",
    contaCorrenteConjunta = "contaCorrenteConjunta",
    contaPoupancaConjunta = "contaPoupancaConjunta",
}

export function translateBankAccountType(enumBankAccountType: BankAccountType): string {
    switch (enumBankAccountType) {
        case BankAccountType.contaCorrente: {
            return strings ? strings["enum"]["BankAccountType"]["contaCorrente"] || BankAccountType.contaCorrente : BankAccountType.contaCorrente;
        }
        case BankAccountType.contaPoupanca: {
            return strings ? strings["enum"]["BankAccountType"]["contaPoupanca"] || BankAccountType.contaPoupanca : BankAccountType.contaPoupanca;
        }
        case BankAccountType.contaCorrenteConjunta: {
            return strings ? strings["enum"]["BankAccountType"]["contaCorrenteConjunta"] || BankAccountType.contaCorrenteConjunta : BankAccountType.contaCorrenteConjunta;
        }
        case BankAccountType.contaPoupancaConjunta: {
            return strings ? strings["enum"]["BankAccountType"]["contaPoupancaConjunta"] || BankAccountType.contaPoupancaConjunta : BankAccountType.contaPoupancaConjunta;
        }
    }
    return "";
}

export function allValuesBankAccountType(): BankAccountType[] {
    return [
        BankAccountType.contaCorrente,
        BankAccountType.contaPoupanca,
        BankAccountType.contaCorrenteConjunta,
        BankAccountType.contaPoupancaConjunta,
    ];
}

export function allTranslatedValuesBankAccountType(): string[] {
    return [
        translateBankAccountType(BankAccountType.contaCorrente),
        translateBankAccountType(BankAccountType.contaPoupanca),
        translateBankAccountType(BankAccountType.contaCorrenteConjunta),
        translateBankAccountType(BankAccountType.contaPoupancaConjunta),
    ];
}

export function allDisplayableValuesBankAccountType(): string[] {
    return allTranslatedValuesBankAccountType().sort();
}

export function valueFromTranslationBankAccountType(translation: string): BankAccountType {
    const index = allTranslatedValuesBankAccountType().indexOf(translation);
    return allValuesBankAccountType()[index] || BankAccountType.contaCorrente;
}

export enum TransactionStatus {
    processing = "processing",
    authorized = "authorized",
    paid = "paid",
    refunded = "refunded",
    waitingPayment = "waitingPayment",
    pendingRefund = "pendingRefund",
    refused = "refused",
    chargedback = "chargedback",
}

export function translateTransactionStatus(enumTransactionStatus: TransactionStatus): string {
    switch (enumTransactionStatus) {
        case TransactionStatus.processing: {
            return strings ? strings["enum"]["TransactionStatus"]["processing"] || TransactionStatus.processing : TransactionStatus.processing;
        }
        case TransactionStatus.authorized: {
            return strings ? strings["enum"]["TransactionStatus"]["authorized"] || TransactionStatus.authorized : TransactionStatus.authorized;
        }
        case TransactionStatus.paid: {
            return strings ? strings["enum"]["TransactionStatus"]["paid"] || TransactionStatus.paid : TransactionStatus.paid;
        }
        case TransactionStatus.refunded: {
            return strings ? strings["enum"]["TransactionStatus"]["refunded"] || TransactionStatus.refunded : TransactionStatus.refunded;
        }
        case TransactionStatus.waitingPayment: {
            return strings ? strings["enum"]["TransactionStatus"]["waitingPayment"] || TransactionStatus.waitingPayment : TransactionStatus.waitingPayment;
        }
        case TransactionStatus.pendingRefund: {
            return strings ? strings["enum"]["TransactionStatus"]["pendingRefund"] || TransactionStatus.pendingRefund : TransactionStatus.pendingRefund;
        }
        case TransactionStatus.refused: {
            return strings ? strings["enum"]["TransactionStatus"]["refused"] || TransactionStatus.refused : TransactionStatus.refused;
        }
        case TransactionStatus.chargedback: {
            return strings ? strings["enum"]["TransactionStatus"]["chargedback"] || TransactionStatus.chargedback : TransactionStatus.chargedback;
        }
    }
    return "";
}

export function allValuesTransactionStatus(): TransactionStatus[] {
    return [
        TransactionStatus.processing,
        TransactionStatus.authorized,
        TransactionStatus.paid,
        TransactionStatus.refunded,
        TransactionStatus.waitingPayment,
        TransactionStatus.pendingRefund,
        TransactionStatus.refused,
        TransactionStatus.chargedback,
    ];
}

export function allTranslatedValuesTransactionStatus(): string[] {
    return [
        translateTransactionStatus(TransactionStatus.processing),
        translateTransactionStatus(TransactionStatus.authorized),
        translateTransactionStatus(TransactionStatus.paid),
        translateTransactionStatus(TransactionStatus.refunded),
        translateTransactionStatus(TransactionStatus.waitingPayment),
        translateTransactionStatus(TransactionStatus.pendingRefund),
        translateTransactionStatus(TransactionStatus.refused),
        translateTransactionStatus(TransactionStatus.chargedback),
    ];
}

export function allDisplayableValuesTransactionStatus(): string[] {
    return allTranslatedValuesTransactionStatus().sort();
}

export function valueFromTranslationTransactionStatus(translation: string): TransactionStatus {
    const index = allTranslatedValuesTransactionStatus().indexOf(translation);
    return allValuesTransactionStatus()[index] || TransactionStatus.processing;
}

export enum ImageFormat {
    png = "png",
    jpeg = "jpeg",
}

export function translateImageFormat(enumImageFormat: ImageFormat): string {
    switch (enumImageFormat) {
        case ImageFormat.png: {
            return strings ? strings["enum"]["ImageFormat"]["png"] || ImageFormat.png : ImageFormat.png;
        }
        case ImageFormat.jpeg: {
            return strings ? strings["enum"]["ImageFormat"]["jpeg"] || ImageFormat.jpeg : ImageFormat.jpeg;
        }
    }
    return "";
}

export function allValuesImageFormat(): ImageFormat[] {
    return [
        ImageFormat.png,
        ImageFormat.jpeg,
    ];
}

export function allTranslatedValuesImageFormat(): string[] {
    return [
        translateImageFormat(ImageFormat.png),
        translateImageFormat(ImageFormat.jpeg),
    ];
}

export function allDisplayableValuesImageFormat(): string[] {
    return allTranslatedValuesImageFormat().sort();
}

export function valueFromTranslationImageFormat(translation: string): ImageFormat {
    const index = allTranslatedValuesImageFormat().indexOf(translation);
    return allValuesImageFormat()[index] || ImageFormat.png;
}

export enum FileFormat {
    pdf = "pdf",
}

export function translateFileFormat(enumFileFormat: FileFormat): string {
    switch (enumFileFormat) {
        case FileFormat.pdf: {
            return strings ? strings["enum"]["FileFormat"]["pdf"] || FileFormat.pdf : FileFormat.pdf;
        }
    }
    return "";
}

export function allValuesFileFormat(): FileFormat[] {
    return [
        FileFormat.pdf,
    ];
}

export function allTranslatedValuesFileFormat(): string[] {
    return [
        translateFileFormat(FileFormat.pdf),
    ];
}

export function allDisplayableValuesFileFormat(): string[] {
    return allTranslatedValuesFileFormat().sort();
}

export function valueFromTranslationFileFormat(translation: string): FileFormat {
    const index = allTranslatedValuesFileFormat().indexOf(translation);
    return allValuesFileFormat()[index] || FileFormat.pdf;
}

export enum ErrorType {
    NotFound = "NotFound",
    MissingArgument = "MissingArgument",
    InvalidArgument = "InvalidArgument",
    InvalidPermission = "InvalidPermission",
    BadFormattedResponse = "BadFormattedResponse",
    InternalError = "InternalError",
    Validation = "Validation",
    EmailOrPasswordWrong = "EmailOrPasswordWrong",
    AlreadyRegistered = "AlreadyRegistered",
    AccessNotAllowed = "AccessNotAllowed",
    EmailAlreadyRegistered = "EmailAlreadyRegistered",
    NickAlreadyRegistered = "NickAlreadyRegistered",
    CPFAlreadyRegistered = "CPFAlreadyRegistered",
    CNPJAlreadyRegistered = "CNPJAlreadyRegistered",
    NotLoggedIn = "NotLoggedIn",
    InvalidAccessToken = "InvalidAccessToken",
    AlreadyBlocked = "AlreadyBlocked",
    AlreadyUnblocked = "AlreadyUnblocked",
    AlreadyFinished = "AlreadyFinished",
    AlreadyCanceled = "AlreadyCanceled",
    AlreadyHasARequestToConsume = "AlreadyHasARequestToConsume",
    PackageAlreadyRegistered = "PackageAlreadyRegistered",
    DocumentNumberOrAddressNull = "DocumentNumberOrAddressNull",
    PagarMeBankAccount = "PagarMeBankAccount",
    PagarMeCreditCard = "PagarMeCreditCard",
    PagarMeTransaction = "PagarMeTransaction",
    PagarMeNotPaid = "PagarMeNotPaid",
    InvalidCEP = "InvalidCEP",
    NeedProfessional = "NeedProfessional",
    WithoutBankAccount = "WithoutBankAccount",
    WithoutBankAccountRecipient = "WithoutBankAccountRecipient",
    RestaurantIsAlreadyBlocked = "RestaurantIsAlreadyBlocked",
    RestaurantIsAlreadyUnblocked = "RestaurantIsAlreadyUnblocked",
    ExceededAvailableConsumeAmount = "ExceededAvailableConsumeAmount",
    PurchasedProductIsInactive = "PurchasedProductIsInactive",
    PurchasedProductWasTotallyConsumed = "PurchasedProductWasTotallyConsumed",
    ConsumptionIsAlreadyApproved = "ConsumptionIsAlreadyApproved",
    DynamicLinkError = "DynamicLinkError",
    Fatal = "Fatal",
    Connection = "Connection",
}

export function translateErrorType(enumErrorType: ErrorType): string {
    switch (enumErrorType) {
        case ErrorType.NotFound: {
            return strings ? strings["enum"]["ErrorType"]["NotFound"] || ErrorType.NotFound : ErrorType.NotFound;
        }
        case ErrorType.MissingArgument: {
            return strings ? strings["enum"]["ErrorType"]["MissingArgument"] || ErrorType.MissingArgument : ErrorType.MissingArgument;
        }
        case ErrorType.InvalidArgument: {
            return strings ? strings["enum"]["ErrorType"]["InvalidArgument"] || ErrorType.InvalidArgument : ErrorType.InvalidArgument;
        }
        case ErrorType.InvalidPermission: {
            return strings ? strings["enum"]["ErrorType"]["InvalidPermission"] || ErrorType.InvalidPermission : ErrorType.InvalidPermission;
        }
        case ErrorType.BadFormattedResponse: {
            return strings ? strings["enum"]["ErrorType"]["BadFormattedResponse"] || ErrorType.BadFormattedResponse : ErrorType.BadFormattedResponse;
        }
        case ErrorType.InternalError: {
            return strings ? strings["enum"]["ErrorType"]["InternalError"] || ErrorType.InternalError : ErrorType.InternalError;
        }
        case ErrorType.Validation: {
            return strings ? strings["enum"]["ErrorType"]["Validation"] || ErrorType.Validation : ErrorType.Validation;
        }
        case ErrorType.EmailOrPasswordWrong: {
            return strings ? strings["enum"]["ErrorType"]["EmailOrPasswordWrong"] || ErrorType.EmailOrPasswordWrong : ErrorType.EmailOrPasswordWrong;
        }
        case ErrorType.AlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyRegistered"] || ErrorType.AlreadyRegistered : ErrorType.AlreadyRegistered;
        }
        case ErrorType.AccessNotAllowed: {
            return strings ? strings["enum"]["ErrorType"]["AccessNotAllowed"] || ErrorType.AccessNotAllowed : ErrorType.AccessNotAllowed;
        }
        case ErrorType.EmailAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["EmailAlreadyRegistered"] || ErrorType.EmailAlreadyRegistered : ErrorType.EmailAlreadyRegistered;
        }
        case ErrorType.NickAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["NickAlreadyRegistered"] || ErrorType.NickAlreadyRegistered : ErrorType.NickAlreadyRegistered;
        }
        case ErrorType.CPFAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["CPFAlreadyRegistered"] || ErrorType.CPFAlreadyRegistered : ErrorType.CPFAlreadyRegistered;
        }
        case ErrorType.CNPJAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["CNPJAlreadyRegistered"] || ErrorType.CNPJAlreadyRegistered : ErrorType.CNPJAlreadyRegistered;
        }
        case ErrorType.NotLoggedIn: {
            return strings ? strings["enum"]["ErrorType"]["NotLoggedIn"] || ErrorType.NotLoggedIn : ErrorType.NotLoggedIn;
        }
        case ErrorType.InvalidAccessToken: {
            return strings ? strings["enum"]["ErrorType"]["InvalidAccessToken"] || ErrorType.InvalidAccessToken : ErrorType.InvalidAccessToken;
        }
        case ErrorType.AlreadyBlocked: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyBlocked"] || ErrorType.AlreadyBlocked : ErrorType.AlreadyBlocked;
        }
        case ErrorType.AlreadyUnblocked: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyUnblocked"] || ErrorType.AlreadyUnblocked : ErrorType.AlreadyUnblocked;
        }
        case ErrorType.AlreadyFinished: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyFinished"] || ErrorType.AlreadyFinished : ErrorType.AlreadyFinished;
        }
        case ErrorType.AlreadyCanceled: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyCanceled"] || ErrorType.AlreadyCanceled : ErrorType.AlreadyCanceled;
        }
        case ErrorType.AlreadyHasARequestToConsume: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyHasARequestToConsume"] || ErrorType.AlreadyHasARequestToConsume : ErrorType.AlreadyHasARequestToConsume;
        }
        case ErrorType.PackageAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["PackageAlreadyRegistered"] || ErrorType.PackageAlreadyRegistered : ErrorType.PackageAlreadyRegistered;
        }
        case ErrorType.DocumentNumberOrAddressNull: {
            return strings ? strings["enum"]["ErrorType"]["DocumentNumberOrAddressNull"] || ErrorType.DocumentNumberOrAddressNull : ErrorType.DocumentNumberOrAddressNull;
        }
        case ErrorType.PagarMeBankAccount: {
            return strings ? strings["enum"]["ErrorType"]["PagarMeBankAccount"] || ErrorType.PagarMeBankAccount : ErrorType.PagarMeBankAccount;
        }
        case ErrorType.PagarMeCreditCard: {
            return strings ? strings["enum"]["ErrorType"]["PagarMeCreditCard"] || ErrorType.PagarMeCreditCard : ErrorType.PagarMeCreditCard;
        }
        case ErrorType.PagarMeTransaction: {
            return strings ? strings["enum"]["ErrorType"]["PagarMeTransaction"] || ErrorType.PagarMeTransaction : ErrorType.PagarMeTransaction;
        }
        case ErrorType.PagarMeNotPaid: {
            return strings ? strings["enum"]["ErrorType"]["PagarMeNotPaid"] || ErrorType.PagarMeNotPaid : ErrorType.PagarMeNotPaid;
        }
        case ErrorType.InvalidCEP: {
            return strings ? strings["enum"]["ErrorType"]["InvalidCEP"] || ErrorType.InvalidCEP : ErrorType.InvalidCEP;
        }
        case ErrorType.NeedProfessional: {
            return strings ? strings["enum"]["ErrorType"]["NeedProfessional"] || ErrorType.NeedProfessional : ErrorType.NeedProfessional;
        }
        case ErrorType.WithoutBankAccount: {
            return strings ? strings["enum"]["ErrorType"]["WithoutBankAccount"] || ErrorType.WithoutBankAccount : ErrorType.WithoutBankAccount;
        }
        case ErrorType.WithoutBankAccountRecipient: {
            return strings ? strings["enum"]["ErrorType"]["WithoutBankAccountRecipient"] || ErrorType.WithoutBankAccountRecipient : ErrorType.WithoutBankAccountRecipient;
        }
        case ErrorType.RestaurantIsAlreadyBlocked: {
            return strings ? strings["enum"]["ErrorType"]["RestaurantIsAlreadyBlocked"] || ErrorType.RestaurantIsAlreadyBlocked : ErrorType.RestaurantIsAlreadyBlocked;
        }
        case ErrorType.RestaurantIsAlreadyUnblocked: {
            return strings ? strings["enum"]["ErrorType"]["RestaurantIsAlreadyUnblocked"] || ErrorType.RestaurantIsAlreadyUnblocked : ErrorType.RestaurantIsAlreadyUnblocked;
        }
        case ErrorType.ExceededAvailableConsumeAmount: {
            return strings ? strings["enum"]["ErrorType"]["ExceededAvailableConsumeAmount"] || ErrorType.ExceededAvailableConsumeAmount : ErrorType.ExceededAvailableConsumeAmount;
        }
        case ErrorType.PurchasedProductIsInactive: {
            return strings ? strings["enum"]["ErrorType"]["PurchasedProductIsInactive"] || ErrorType.PurchasedProductIsInactive : ErrorType.PurchasedProductIsInactive;
        }
        case ErrorType.PurchasedProductWasTotallyConsumed: {
            return strings ? strings["enum"]["ErrorType"]["PurchasedProductWasTotallyConsumed"] || ErrorType.PurchasedProductWasTotallyConsumed : ErrorType.PurchasedProductWasTotallyConsumed;
        }
        case ErrorType.ConsumptionIsAlreadyApproved: {
            return strings ? strings["enum"]["ErrorType"]["ConsumptionIsAlreadyApproved"] || ErrorType.ConsumptionIsAlreadyApproved : ErrorType.ConsumptionIsAlreadyApproved;
        }
        case ErrorType.DynamicLinkError: {
            return strings ? strings["enum"]["ErrorType"]["DynamicLinkError"] || ErrorType.DynamicLinkError : ErrorType.DynamicLinkError;
        }
        case ErrorType.Fatal: {
            return strings ? strings["enum"]["ErrorType"]["Fatal"] || ErrorType.Fatal : ErrorType.Fatal;
        }
        case ErrorType.Connection: {
            return strings ? strings["enum"]["ErrorType"]["Connection"] || ErrorType.Connection : ErrorType.Connection;
        }
    }
    return "";
}

export function allValuesErrorType(): ErrorType[] {
    return [
        ErrorType.NotFound,
        ErrorType.MissingArgument,
        ErrorType.InvalidArgument,
        ErrorType.InvalidPermission,
        ErrorType.BadFormattedResponse,
        ErrorType.InternalError,
        ErrorType.Validation,
        ErrorType.EmailOrPasswordWrong,
        ErrorType.AlreadyRegistered,
        ErrorType.AccessNotAllowed,
        ErrorType.EmailAlreadyRegistered,
        ErrorType.NickAlreadyRegistered,
        ErrorType.CPFAlreadyRegistered,
        ErrorType.CNPJAlreadyRegistered,
        ErrorType.NotLoggedIn,
        ErrorType.InvalidAccessToken,
        ErrorType.AlreadyBlocked,
        ErrorType.AlreadyUnblocked,
        ErrorType.AlreadyFinished,
        ErrorType.AlreadyCanceled,
        ErrorType.AlreadyHasARequestToConsume,
        ErrorType.PackageAlreadyRegistered,
        ErrorType.DocumentNumberOrAddressNull,
        ErrorType.PagarMeBankAccount,
        ErrorType.PagarMeCreditCard,
        ErrorType.PagarMeTransaction,
        ErrorType.PagarMeNotPaid,
        ErrorType.InvalidCEP,
        ErrorType.NeedProfessional,
        ErrorType.WithoutBankAccount,
        ErrorType.WithoutBankAccountRecipient,
        ErrorType.RestaurantIsAlreadyBlocked,
        ErrorType.RestaurantIsAlreadyUnblocked,
        ErrorType.ExceededAvailableConsumeAmount,
        ErrorType.PurchasedProductIsInactive,
        ErrorType.PurchasedProductWasTotallyConsumed,
        ErrorType.ConsumptionIsAlreadyApproved,
        ErrorType.DynamicLinkError,
        ErrorType.Fatal,
        ErrorType.Connection,
    ];
}

export function allTranslatedValuesErrorType(): string[] {
    return [
        translateErrorType(ErrorType.NotFound),
        translateErrorType(ErrorType.MissingArgument),
        translateErrorType(ErrorType.InvalidArgument),
        translateErrorType(ErrorType.InvalidPermission),
        translateErrorType(ErrorType.BadFormattedResponse),
        translateErrorType(ErrorType.InternalError),
        translateErrorType(ErrorType.Validation),
        translateErrorType(ErrorType.EmailOrPasswordWrong),
        translateErrorType(ErrorType.AlreadyRegistered),
        translateErrorType(ErrorType.AccessNotAllowed),
        translateErrorType(ErrorType.EmailAlreadyRegistered),
        translateErrorType(ErrorType.NickAlreadyRegistered),
        translateErrorType(ErrorType.CPFAlreadyRegistered),
        translateErrorType(ErrorType.CNPJAlreadyRegistered),
        translateErrorType(ErrorType.NotLoggedIn),
        translateErrorType(ErrorType.InvalidAccessToken),
        translateErrorType(ErrorType.AlreadyBlocked),
        translateErrorType(ErrorType.AlreadyUnblocked),
        translateErrorType(ErrorType.AlreadyFinished),
        translateErrorType(ErrorType.AlreadyCanceled),
        translateErrorType(ErrorType.AlreadyHasARequestToConsume),
        translateErrorType(ErrorType.PackageAlreadyRegistered),
        translateErrorType(ErrorType.DocumentNumberOrAddressNull),
        translateErrorType(ErrorType.PagarMeBankAccount),
        translateErrorType(ErrorType.PagarMeCreditCard),
        translateErrorType(ErrorType.PagarMeTransaction),
        translateErrorType(ErrorType.PagarMeNotPaid),
        translateErrorType(ErrorType.InvalidCEP),
        translateErrorType(ErrorType.NeedProfessional),
        translateErrorType(ErrorType.WithoutBankAccount),
        translateErrorType(ErrorType.WithoutBankAccountRecipient),
        translateErrorType(ErrorType.RestaurantIsAlreadyBlocked),
        translateErrorType(ErrorType.RestaurantIsAlreadyUnblocked),
        translateErrorType(ErrorType.ExceededAvailableConsumeAmount),
        translateErrorType(ErrorType.PurchasedProductIsInactive),
        translateErrorType(ErrorType.PurchasedProductWasTotallyConsumed),
        translateErrorType(ErrorType.ConsumptionIsAlreadyApproved),
        translateErrorType(ErrorType.DynamicLinkError),
        translateErrorType(ErrorType.Fatal),
        translateErrorType(ErrorType.Connection),
    ];
}

export function allDisplayableValuesErrorType(): string[] {
    return allTranslatedValuesErrorType().sort();
}

export function valueFromTranslationErrorType(translation: string): ErrorType {
    const index = allTranslatedValuesErrorType().indexOf(translation);
    return allValuesErrorType()[index] || ErrorType.NotFound;
}

export async function uploadUncertainFile(file: UncertainFile, fileFormat: FileFormat | null, progress?: (progress: number) => void): Promise<string> {
    const args = {
        file: {
            bytes: file.bytes === null || file.bytes === undefined ? null : file.bytes.toString("base64"),
            url: file.url === null || file.url === undefined ? null : file.url,
        },
        fileFormat: fileFormat === null || fileFormat === undefined ? null : fileFormat,
    };
    const ret = await makeRequest({name: "uploadUncertainFile", args, progress});
    return ret;
}

export async function uploadImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function createOrEditBankAccountForRestaurantAdminUser(newBankAccount: NewBankAccount, progress?: (progress: number) => void): Promise<BankAccount> {
    const args = {
        newBankAccount: {
            bankName: newBankAccount.bankName,
            bankCode: newBankAccount.bankCode,
            agency: newBankAccount.agency,
            agencyDv: newBankAccount.agencyDv === null || newBankAccount.agencyDv === undefined ? null : newBankAccount.agencyDv,
            account: newBankAccount.account,
            accountDv: newBankAccount.accountDv === null || newBankAccount.accountDv === undefined ? null : newBankAccount.accountDv,
            type: newBankAccount.type,
            documentNumber: newBankAccount.documentNumber,
        },
    };
    const ret = await makeRequest({name: "createOrEditBankAccountForRestaurantAdminUser", args, progress});
    return {
        id: ret.id,
        bankName: ret.bankName,
        bankCode: ret.bankCode,
        agency: ret.agency,
        agencyDv: ret.agencyDv === null || ret.agencyDv === undefined ? null : ret.agencyDv,
        account: ret.account,
        accountDv: ret.accountDv === null || ret.accountDv === undefined ? null : ret.accountDv,
        type: ret.type,
        documentNumber: ret.documentNumber,
    };
}

export async function getBankAccountForRestaurantAdminUser(progress?: (progress: number) => void): Promise<BankAccount> {
    const ret = await makeRequest({name: "getBankAccountForRestaurantAdminUser", args: {}, progress});
    return {
        id: ret.id,
        bankName: ret.bankName,
        bankCode: ret.bankCode,
        agency: ret.agency,
        agencyDv: ret.agencyDv === null || ret.agencyDv === undefined ? null : ret.agencyDv,
        account: ret.account,
        accountDv: ret.accountDv === null || ret.accountDv === undefined ? null : ret.accountDv,
        type: ret.type,
        documentNumber: ret.documentNumber,
    };
}

export async function getAllRestaurants(pageOffset: number, createdAt: Date | null, progress?: (progress: number) => void): Promise<Restaurant[]> {
    const args = {
        pageOffset: pageOffset || 0,
        createdAt: createdAt === null || createdAt === undefined ? null : (typeof createdAt === "string" && (createdAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (createdAt as any).replace("Z", "") : createdAt.toISOString().replace("Z", "")),
    };
    const ret = await makeRequest({name: "getAllRestaurants", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        corporateName: e.corporateName,
        documentNumber: e.documentNumber,
        address: {
            zipcode: e.address.zipcode,
            street: e.address.street,
            streetNumber: e.address.streetNumber,
            complementary: e.address.complementary === null || e.address.complementary === undefined ? null : e.address.complementary,
            neighborhood: e.address.neighborhood,
            city: e.address.city,
            state: e.address.state,
            countryCode: e.address.countryCode,
        },
        createdAt: e.createdAt === null || e.createdAt === undefined ? null : new Date(e.createdAt + "Z"),
        bankAccount: e.bankAccount === null || e.bankAccount === undefined ? null : {
            id: e.bankAccount.id,
            bankName: e.bankAccount.bankName,
            bankCode: e.bankAccount.bankCode,
            agency: e.bankAccount.agency,
            agencyDv: e.bankAccount.agencyDv === null || e.bankAccount.agencyDv === undefined ? null : e.bankAccount.agencyDv,
            account: e.bankAccount.account,
            accountDv: e.bankAccount.accountDv === null || e.bankAccount.accountDv === undefined ? null : e.bankAccount.accountDv,
            type: e.bankAccount.type,
            documentNumber: e.bankAccount.documentNumber,
        },
        blockedAt: e.blockedAt === null || e.blockedAt === undefined ? null : new Date(parseInt(e.blockedAt.split("-")[0], 10), parseInt(e.blockedAt.split("-")[1], 10) - 1, parseInt(e.blockedAt.split("-")[2], 10)),
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
    }));
}

export async function getRestaurantBalance(progress?: (progress: number) => void): Promise<Balance> {
    const ret = await makeRequest({name: "getRestaurantBalance", args: {}, progress});
    return {
        waiting: ret.waiting || 0,
        available: ret.available || 0,
        transferred: ret.transferred || 0,
    };
}

export async function getRestaurantById(id: string, progress?: (progress: number) => void): Promise<Restaurant> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getRestaurantById", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        corporateName: ret.corporateName,
        documentNumber: ret.documentNumber,
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
        },
        createdAt: ret.createdAt === null || ret.createdAt === undefined ? null : new Date(ret.createdAt + "Z"),
        bankAccount: ret.bankAccount === null || ret.bankAccount === undefined ? null : {
            id: ret.bankAccount.id,
            bankName: ret.bankAccount.bankName,
            bankCode: ret.bankAccount.bankCode,
            agency: ret.bankAccount.agency,
            agencyDv: ret.bankAccount.agencyDv === null || ret.bankAccount.agencyDv === undefined ? null : ret.bankAccount.agencyDv,
            account: ret.bankAccount.account,
            accountDv: ret.bankAccount.accountDv === null || ret.bankAccount.accountDv === undefined ? null : ret.bankAccount.accountDv,
            type: ret.bankAccount.type,
            documentNumber: ret.bankAccount.documentNumber,
        },
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
    };
}

export async function getRestaurantDynamicLink(id: string, progress?: (progress: number) => void): Promise<string> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getRestaurantDynamicLink", args, progress});
    return ret;
}

export async function autocompleteRestaurant(name: string, pageOffset: number, progress?: (progress: number) => void): Promise<Restaurant[]> {
    const args = {
        name: name,
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "autocompleteRestaurant", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        corporateName: e.corporateName,
        documentNumber: e.documentNumber,
        address: {
            zipcode: e.address.zipcode,
            street: e.address.street,
            streetNumber: e.address.streetNumber,
            complementary: e.address.complementary === null || e.address.complementary === undefined ? null : e.address.complementary,
            neighborhood: e.address.neighborhood,
            city: e.address.city,
            state: e.address.state,
            countryCode: e.address.countryCode,
        },
        createdAt: e.createdAt === null || e.createdAt === undefined ? null : new Date(e.createdAt + "Z"),
        bankAccount: e.bankAccount === null || e.bankAccount === undefined ? null : {
            id: e.bankAccount.id,
            bankName: e.bankAccount.bankName,
            bankCode: e.bankAccount.bankCode,
            agency: e.bankAccount.agency,
            agencyDv: e.bankAccount.agencyDv === null || e.bankAccount.agencyDv === undefined ? null : e.bankAccount.agencyDv,
            account: e.bankAccount.account,
            accountDv: e.bankAccount.accountDv === null || e.bankAccount.accountDv === undefined ? null : e.bankAccount.accountDv,
            type: e.bankAccount.type,
            documentNumber: e.bankAccount.documentNumber,
        },
        blockedAt: e.blockedAt === null || e.blockedAt === undefined ? null : new Date(parseInt(e.blockedAt.split("-")[0], 10), parseInt(e.blockedAt.split("-")[1], 10) - 1, parseInt(e.blockedAt.split("-")[2], 10)),
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
    }));
}

export async function deleteRestaurant(id: string, progress?: (progress: number) => void): Promise<Restaurant> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteRestaurant", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        corporateName: ret.corporateName,
        documentNumber: ret.documentNumber,
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
        },
        createdAt: ret.createdAt === null || ret.createdAt === undefined ? null : new Date(ret.createdAt + "Z"),
        bankAccount: ret.bankAccount === null || ret.bankAccount === undefined ? null : {
            id: ret.bankAccount.id,
            bankName: ret.bankAccount.bankName,
            bankCode: ret.bankAccount.bankCode,
            agency: ret.bankAccount.agency,
            agencyDv: ret.bankAccount.agencyDv === null || ret.bankAccount.agencyDv === undefined ? null : ret.bankAccount.agencyDv,
            account: ret.bankAccount.account,
            accountDv: ret.bankAccount.accountDv === null || ret.bankAccount.accountDv === undefined ? null : ret.bankAccount.accountDv,
            type: ret.bankAccount.type,
            documentNumber: ret.bankAccount.documentNumber,
        },
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
    };
}

export async function createRestaurant(newRestaurant: NewRestaurant, progress?: (progress: number) => void): Promise<Restaurant> {
    const args = {
        newRestaurant: {
            name: newRestaurant.name,
            corporateName: newRestaurant.corporateName,
            documentNumber: newRestaurant.documentNumber,
            address: {
                zipcode: newRestaurant.address.zipcode,
                street: newRestaurant.address.street,
                streetNumber: newRestaurant.address.streetNumber,
                complementary: newRestaurant.address.complementary === null || newRestaurant.address.complementary === undefined ? null : newRestaurant.address.complementary,
                neighborhood: newRestaurant.address.neighborhood,
                city: newRestaurant.address.city,
                state: newRestaurant.address.state,
                countryCode: newRestaurant.address.countryCode,
            },
            image: newRestaurant.image === null || newRestaurant.image === undefined ? null : {
                bytes: newRestaurant.image.bytes === null || newRestaurant.image.bytes === undefined ? null : newRestaurant.image.bytes.toString("base64"),
                image: newRestaurant.image.image === null || newRestaurant.image.image === undefined ? null : {
                    thumb: {
                        width: newRestaurant.image.image.thumb.width || 0,
                        height: newRestaurant.image.image.thumb.height || 0,
                        url: newRestaurant.image.image.thumb.url,
                    },
                    width: newRestaurant.image.image.width || 0,
                    height: newRestaurant.image.image.height || 0,
                    url: newRestaurant.image.image.url,
                },
            },
        },
    };
    const ret = await makeRequest({name: "createRestaurant", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        corporateName: ret.corporateName,
        documentNumber: ret.documentNumber,
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
        },
        createdAt: ret.createdAt === null || ret.createdAt === undefined ? null : new Date(ret.createdAt + "Z"),
        bankAccount: ret.bankAccount === null || ret.bankAccount === undefined ? null : {
            id: ret.bankAccount.id,
            bankName: ret.bankAccount.bankName,
            bankCode: ret.bankAccount.bankCode,
            agency: ret.bankAccount.agency,
            agencyDv: ret.bankAccount.agencyDv === null || ret.bankAccount.agencyDv === undefined ? null : ret.bankAccount.agencyDv,
            account: ret.bankAccount.account,
            accountDv: ret.bankAccount.accountDv === null || ret.bankAccount.accountDv === undefined ? null : ret.bankAccount.accountDv,
            type: ret.bankAccount.type,
            documentNumber: ret.bankAccount.documentNumber,
        },
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
    };
}

export async function editRestaurant(id: string, editRestaurant: EditRestaurant, progress?: (progress: number) => void): Promise<Restaurant> {
    const args = {
        id: id,
        editRestaurant: {
            name: editRestaurant.name,
            corporateName: editRestaurant.corporateName,
            documentNumber: editRestaurant.documentNumber,
            address: {
                zipcode: editRestaurant.address.zipcode,
                street: editRestaurant.address.street,
                streetNumber: editRestaurant.address.streetNumber,
                complementary: editRestaurant.address.complementary === null || editRestaurant.address.complementary === undefined ? null : editRestaurant.address.complementary,
                neighborhood: editRestaurant.address.neighborhood,
                city: editRestaurant.address.city,
                state: editRestaurant.address.state,
                countryCode: editRestaurant.address.countryCode,
            },
            image: editRestaurant.image === null || editRestaurant.image === undefined ? null : {
                bytes: editRestaurant.image.bytes === null || editRestaurant.image.bytes === undefined ? null : editRestaurant.image.bytes.toString("base64"),
                image: editRestaurant.image.image === null || editRestaurant.image.image === undefined ? null : {
                    thumb: {
                        width: editRestaurant.image.image.thumb.width || 0,
                        height: editRestaurant.image.image.thumb.height || 0,
                        url: editRestaurant.image.image.thumb.url,
                    },
                    width: editRestaurant.image.image.width || 0,
                    height: editRestaurant.image.image.height || 0,
                    url: editRestaurant.image.image.url,
                },
            },
        },
    };
    const ret = await makeRequest({name: "editRestaurant", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        corporateName: ret.corporateName,
        documentNumber: ret.documentNumber,
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
        },
        createdAt: ret.createdAt === null || ret.createdAt === undefined ? null : new Date(ret.createdAt + "Z"),
        bankAccount: ret.bankAccount === null || ret.bankAccount === undefined ? null : {
            id: ret.bankAccount.id,
            bankName: ret.bankAccount.bankName,
            bankCode: ret.bankAccount.bankCode,
            agency: ret.bankAccount.agency,
            agencyDv: ret.bankAccount.agencyDv === null || ret.bankAccount.agencyDv === undefined ? null : ret.bankAccount.agencyDv,
            account: ret.bankAccount.account,
            accountDv: ret.bankAccount.accountDv === null || ret.bankAccount.accountDv === undefined ? null : ret.bankAccount.accountDv,
            type: ret.bankAccount.type,
            documentNumber: ret.bankAccount.documentNumber,
        },
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
    };
}

export async function changeRestaurantBlockStatus(id: string, willBeBlocked: boolean, progress?: (progress: number) => void): Promise<Restaurant> {
    const args = {
        id: id,
        willBeBlocked: !!willBeBlocked,
    };
    const ret = await makeRequest({name: "changeRestaurantBlockStatus", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        corporateName: ret.corporateName,
        documentNumber: ret.documentNumber,
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
        },
        createdAt: ret.createdAt === null || ret.createdAt === undefined ? null : new Date(ret.createdAt + "Z"),
        bankAccount: ret.bankAccount === null || ret.bankAccount === undefined ? null : {
            id: ret.bankAccount.id,
            bankName: ret.bankAccount.bankName,
            bankCode: ret.bankAccount.bankCode,
            agency: ret.bankAccount.agency,
            agencyDv: ret.bankAccount.agencyDv === null || ret.bankAccount.agencyDv === undefined ? null : ret.bankAccount.agencyDv,
            account: ret.bankAccount.account,
            accountDv: ret.bankAccount.accountDv === null || ret.bankAccount.accountDv === undefined ? null : ret.bankAccount.accountDv,
            type: ret.bankAccount.type,
            documentNumber: ret.bankAccount.documentNumber,
        },
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
    };
}

export async function editRestaurantOfRestaurantAdminUser(editRestaurant: EditRestaurantForRestaurantAdmin, progress?: (progress: number) => void): Promise<Restaurant> {
    const args = {
        editRestaurant: {
            name: editRestaurant.name,
            corporateName: editRestaurant.corporateName,
            address: {
                zipcode: editRestaurant.address.zipcode,
                street: editRestaurant.address.street,
                streetNumber: editRestaurant.address.streetNumber,
                complementary: editRestaurant.address.complementary === null || editRestaurant.address.complementary === undefined ? null : editRestaurant.address.complementary,
                neighborhood: editRestaurant.address.neighborhood,
                city: editRestaurant.address.city,
                state: editRestaurant.address.state,
                countryCode: editRestaurant.address.countryCode,
            },
            image: editRestaurant.image === null || editRestaurant.image === undefined ? null : {
                bytes: editRestaurant.image.bytes === null || editRestaurant.image.bytes === undefined ? null : editRestaurant.image.bytes.toString("base64"),
                image: editRestaurant.image.image === null || editRestaurant.image.image === undefined ? null : {
                    thumb: {
                        width: editRestaurant.image.image.thumb.width || 0,
                        height: editRestaurant.image.image.thumb.height || 0,
                        url: editRestaurant.image.image.thumb.url,
                    },
                    width: editRestaurant.image.image.width || 0,
                    height: editRestaurant.image.image.height || 0,
                    url: editRestaurant.image.image.url,
                },
            },
        },
    };
    const ret = await makeRequest({name: "editRestaurantOfRestaurantAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        corporateName: ret.corporateName,
        documentNumber: ret.documentNumber,
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
        },
        createdAt: ret.createdAt === null || ret.createdAt === undefined ? null : new Date(ret.createdAt + "Z"),
        bankAccount: ret.bankAccount === null || ret.bankAccount === undefined ? null : {
            id: ret.bankAccount.id,
            bankName: ret.bankAccount.bankName,
            bankCode: ret.bankAccount.bankCode,
            agency: ret.bankAccount.agency,
            agencyDv: ret.bankAccount.agencyDv === null || ret.bankAccount.agencyDv === undefined ? null : ret.bankAccount.agencyDv,
            account: ret.bankAccount.account,
            accountDv: ret.bankAccount.accountDv === null || ret.bankAccount.accountDv === undefined ? null : ret.bankAccount.accountDv,
            type: ret.bankAccount.type,
            documentNumber: ret.bankAccount.documentNumber,
        },
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(parseInt(ret.blockedAt.split("-")[0], 10), parseInt(ret.blockedAt.split("-")[1], 10) - 1, parseInt(ret.blockedAt.split("-")[2], 10)),
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
    };
}

export async function createTransfer(amount: number, progress?: (progress: number) => void): Promise<Balance> {
    const args = {
        amount: amount || 0,
    };
    const ret = await makeRequest({name: "createTransfer", args, progress});
    return {
        waiting: ret.waiting || 0,
        available: ret.available || 0,
        transferred: ret.transferred || 0,
    };
}

export async function getCurrentAdminLogged(progress?: (progress: number) => void): Promise<AdminUser> {
    const ret = await makeRequest({name: "getCurrentAdminLogged", args: {}, progress});
    return {
        id: ret.id,
        restaurant: ret.restaurant === null || ret.restaurant === undefined ? null : {
            id: ret.restaurant.id,
            name: ret.restaurant.name,
            corporateName: ret.restaurant.corporateName,
            documentNumber: ret.restaurant.documentNumber,
            address: {
                zipcode: ret.restaurant.address.zipcode,
                street: ret.restaurant.address.street,
                streetNumber: ret.restaurant.address.streetNumber,
                complementary: ret.restaurant.address.complementary === null || ret.restaurant.address.complementary === undefined ? null : ret.restaurant.address.complementary,
                neighborhood: ret.restaurant.address.neighborhood,
                city: ret.restaurant.address.city,
                state: ret.restaurant.address.state,
                countryCode: ret.restaurant.address.countryCode,
            },
            createdAt: ret.restaurant.createdAt === null || ret.restaurant.createdAt === undefined ? null : new Date(ret.restaurant.createdAt + "Z"),
            bankAccount: ret.restaurant.bankAccount === null || ret.restaurant.bankAccount === undefined ? null : {
                id: ret.restaurant.bankAccount.id,
                bankName: ret.restaurant.bankAccount.bankName,
                bankCode: ret.restaurant.bankAccount.bankCode,
                agency: ret.restaurant.bankAccount.agency,
                agencyDv: ret.restaurant.bankAccount.agencyDv === null || ret.restaurant.bankAccount.agencyDv === undefined ? null : ret.restaurant.bankAccount.agencyDv,
                account: ret.restaurant.bankAccount.account,
                accountDv: ret.restaurant.bankAccount.accountDv === null || ret.restaurant.bankAccount.accountDv === undefined ? null : ret.restaurant.bankAccount.accountDv,
                type: ret.restaurant.bankAccount.type,
                documentNumber: ret.restaurant.bankAccount.documentNumber,
            },
            blockedAt: ret.restaurant.blockedAt === null || ret.restaurant.blockedAt === undefined ? null : new Date(parseInt(ret.restaurant.blockedAt.split("-")[0], 10), parseInt(ret.restaurant.blockedAt.split("-")[1], 10) - 1, parseInt(ret.restaurant.blockedAt.split("-")[2], 10)),
            image: ret.restaurant.image === null || ret.restaurant.image === undefined ? null : {
                thumb: {
                    width: ret.restaurant.image.thumb.width || 0,
                    height: ret.restaurant.image.thumb.height || 0,
                    url: ret.restaurant.image.thumb.url,
                },
                width: ret.restaurant.image.width || 0,
                height: ret.restaurant.image.height || 0,
                url: ret.restaurant.image.url,
            },
        },
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
    };
}

export async function getAdminUser(id: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getAdminUser", args, progress});
    return {
        id: ret.id,
        restaurant: ret.restaurant === null || ret.restaurant === undefined ? null : {
            id: ret.restaurant.id,
            name: ret.restaurant.name,
            corporateName: ret.restaurant.corporateName,
            documentNumber: ret.restaurant.documentNumber,
            address: {
                zipcode: ret.restaurant.address.zipcode,
                street: ret.restaurant.address.street,
                streetNumber: ret.restaurant.address.streetNumber,
                complementary: ret.restaurant.address.complementary === null || ret.restaurant.address.complementary === undefined ? null : ret.restaurant.address.complementary,
                neighborhood: ret.restaurant.address.neighborhood,
                city: ret.restaurant.address.city,
                state: ret.restaurant.address.state,
                countryCode: ret.restaurant.address.countryCode,
            },
            createdAt: ret.restaurant.createdAt === null || ret.restaurant.createdAt === undefined ? null : new Date(ret.restaurant.createdAt + "Z"),
            bankAccount: ret.restaurant.bankAccount === null || ret.restaurant.bankAccount === undefined ? null : {
                id: ret.restaurant.bankAccount.id,
                bankName: ret.restaurant.bankAccount.bankName,
                bankCode: ret.restaurant.bankAccount.bankCode,
                agency: ret.restaurant.bankAccount.agency,
                agencyDv: ret.restaurant.bankAccount.agencyDv === null || ret.restaurant.bankAccount.agencyDv === undefined ? null : ret.restaurant.bankAccount.agencyDv,
                account: ret.restaurant.bankAccount.account,
                accountDv: ret.restaurant.bankAccount.accountDv === null || ret.restaurant.bankAccount.accountDv === undefined ? null : ret.restaurant.bankAccount.accountDv,
                type: ret.restaurant.bankAccount.type,
                documentNumber: ret.restaurant.bankAccount.documentNumber,
            },
            blockedAt: ret.restaurant.blockedAt === null || ret.restaurant.blockedAt === undefined ? null : new Date(parseInt(ret.restaurant.blockedAt.split("-")[0], 10), parseInt(ret.restaurant.blockedAt.split("-")[1], 10) - 1, parseInt(ret.restaurant.blockedAt.split("-")[2], 10)),
            image: ret.restaurant.image === null || ret.restaurant.image === undefined ? null : {
                thumb: {
                    width: ret.restaurant.image.thumb.width || 0,
                    height: ret.restaurant.image.thumb.height || 0,
                    url: ret.restaurant.image.thumb.url,
                },
                width: ret.restaurant.image.width || 0,
                height: ret.restaurant.image.height || 0,
                url: ret.restaurant.image.url,
            },
        },
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
    };
}

export async function getAllAdminUsers(pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllAdminUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        restaurant: e.restaurant === null || e.restaurant === undefined ? null : {
            id: e.restaurant.id,
            name: e.restaurant.name,
            corporateName: e.restaurant.corporateName,
            documentNumber: e.restaurant.documentNumber,
            address: {
                zipcode: e.restaurant.address.zipcode,
                street: e.restaurant.address.street,
                streetNumber: e.restaurant.address.streetNumber,
                complementary: e.restaurant.address.complementary === null || e.restaurant.address.complementary === undefined ? null : e.restaurant.address.complementary,
                neighborhood: e.restaurant.address.neighborhood,
                city: e.restaurant.address.city,
                state: e.restaurant.address.state,
                countryCode: e.restaurant.address.countryCode,
            },
            createdAt: e.restaurant.createdAt === null || e.restaurant.createdAt === undefined ? null : new Date(e.restaurant.createdAt + "Z"),
            bankAccount: e.restaurant.bankAccount === null || e.restaurant.bankAccount === undefined ? null : {
                id: e.restaurant.bankAccount.id,
                bankName: e.restaurant.bankAccount.bankName,
                bankCode: e.restaurant.bankAccount.bankCode,
                agency: e.restaurant.bankAccount.agency,
                agencyDv: e.restaurant.bankAccount.agencyDv === null || e.restaurant.bankAccount.agencyDv === undefined ? null : e.restaurant.bankAccount.agencyDv,
                account: e.restaurant.bankAccount.account,
                accountDv: e.restaurant.bankAccount.accountDv === null || e.restaurant.bankAccount.accountDv === undefined ? null : e.restaurant.bankAccount.accountDv,
                type: e.restaurant.bankAccount.type,
                documentNumber: e.restaurant.bankAccount.documentNumber,
            },
            blockedAt: e.restaurant.blockedAt === null || e.restaurant.blockedAt === undefined ? null : new Date(parseInt(e.restaurant.blockedAt.split("-")[0], 10), parseInt(e.restaurant.blockedAt.split("-")[1], 10) - 1, parseInt(e.restaurant.blockedAt.split("-")[2], 10)),
            image: e.restaurant.image === null || e.restaurant.image === undefined ? null : {
                thumb: {
                    width: e.restaurant.image.thumb.width || 0,
                    height: e.restaurant.image.thumb.height || 0,
                    url: e.restaurant.image.thumb.url,
                },
                width: e.restaurant.image.width || 0,
                height: e.restaurant.image.height || 0,
                url: e.restaurant.image.url,
            },
        },
        createdAt: new Date(e.createdAt + "Z"),
        name: e.name,
        email: e.email,
    }));
}

export async function getAllAdminUsersByRestaurantId(pageOffset: number, restaurantId: string, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
        restaurantId: restaurantId,
    };
    const ret = await makeRequest({name: "getAllAdminUsersByRestaurantId", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        restaurant: e.restaurant === null || e.restaurant === undefined ? null : {
            id: e.restaurant.id,
            name: e.restaurant.name,
            corporateName: e.restaurant.corporateName,
            documentNumber: e.restaurant.documentNumber,
            address: {
                zipcode: e.restaurant.address.zipcode,
                street: e.restaurant.address.street,
                streetNumber: e.restaurant.address.streetNumber,
                complementary: e.restaurant.address.complementary === null || e.restaurant.address.complementary === undefined ? null : e.restaurant.address.complementary,
                neighborhood: e.restaurant.address.neighborhood,
                city: e.restaurant.address.city,
                state: e.restaurant.address.state,
                countryCode: e.restaurant.address.countryCode,
            },
            createdAt: e.restaurant.createdAt === null || e.restaurant.createdAt === undefined ? null : new Date(e.restaurant.createdAt + "Z"),
            bankAccount: e.restaurant.bankAccount === null || e.restaurant.bankAccount === undefined ? null : {
                id: e.restaurant.bankAccount.id,
                bankName: e.restaurant.bankAccount.bankName,
                bankCode: e.restaurant.bankAccount.bankCode,
                agency: e.restaurant.bankAccount.agency,
                agencyDv: e.restaurant.bankAccount.agencyDv === null || e.restaurant.bankAccount.agencyDv === undefined ? null : e.restaurant.bankAccount.agencyDv,
                account: e.restaurant.bankAccount.account,
                accountDv: e.restaurant.bankAccount.accountDv === null || e.restaurant.bankAccount.accountDv === undefined ? null : e.restaurant.bankAccount.accountDv,
                type: e.restaurant.bankAccount.type,
                documentNumber: e.restaurant.bankAccount.documentNumber,
            },
            blockedAt: e.restaurant.blockedAt === null || e.restaurant.blockedAt === undefined ? null : new Date(parseInt(e.restaurant.blockedAt.split("-")[0], 10), parseInt(e.restaurant.blockedAt.split("-")[1], 10) - 1, parseInt(e.restaurant.blockedAt.split("-")[2], 10)),
            image: e.restaurant.image === null || e.restaurant.image === undefined ? null : {
                thumb: {
                    width: e.restaurant.image.thumb.width || 0,
                    height: e.restaurant.image.thumb.height || 0,
                    url: e.restaurant.image.thumb.url,
                },
                width: e.restaurant.image.width || 0,
                height: e.restaurant.image.height || 0,
                url: e.restaurant.image.url,
            },
        },
        createdAt: new Date(e.createdAt + "Z"),
        name: e.name,
        email: e.email,
    }));
}

export async function getAdminRestaurantUser(id: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getAdminRestaurantUser", args, progress});
    return {
        id: ret.id,
        restaurant: ret.restaurant === null || ret.restaurant === undefined ? null : {
            id: ret.restaurant.id,
            name: ret.restaurant.name,
            corporateName: ret.restaurant.corporateName,
            documentNumber: ret.restaurant.documentNumber,
            address: {
                zipcode: ret.restaurant.address.zipcode,
                street: ret.restaurant.address.street,
                streetNumber: ret.restaurant.address.streetNumber,
                complementary: ret.restaurant.address.complementary === null || ret.restaurant.address.complementary === undefined ? null : ret.restaurant.address.complementary,
                neighborhood: ret.restaurant.address.neighborhood,
                city: ret.restaurant.address.city,
                state: ret.restaurant.address.state,
                countryCode: ret.restaurant.address.countryCode,
            },
            createdAt: ret.restaurant.createdAt === null || ret.restaurant.createdAt === undefined ? null : new Date(ret.restaurant.createdAt + "Z"),
            bankAccount: ret.restaurant.bankAccount === null || ret.restaurant.bankAccount === undefined ? null : {
                id: ret.restaurant.bankAccount.id,
                bankName: ret.restaurant.bankAccount.bankName,
                bankCode: ret.restaurant.bankAccount.bankCode,
                agency: ret.restaurant.bankAccount.agency,
                agencyDv: ret.restaurant.bankAccount.agencyDv === null || ret.restaurant.bankAccount.agencyDv === undefined ? null : ret.restaurant.bankAccount.agencyDv,
                account: ret.restaurant.bankAccount.account,
                accountDv: ret.restaurant.bankAccount.accountDv === null || ret.restaurant.bankAccount.accountDv === undefined ? null : ret.restaurant.bankAccount.accountDv,
                type: ret.restaurant.bankAccount.type,
                documentNumber: ret.restaurant.bankAccount.documentNumber,
            },
            blockedAt: ret.restaurant.blockedAt === null || ret.restaurant.blockedAt === undefined ? null : new Date(parseInt(ret.restaurant.blockedAt.split("-")[0], 10), parseInt(ret.restaurant.blockedAt.split("-")[1], 10) - 1, parseInt(ret.restaurant.blockedAt.split("-")[2], 10)),
            image: ret.restaurant.image === null || ret.restaurant.image === undefined ? null : {
                thumb: {
                    width: ret.restaurant.image.thumb.width || 0,
                    height: ret.restaurant.image.thumb.height || 0,
                    url: ret.restaurant.image.thumb.url,
                },
                width: ret.restaurant.image.width || 0,
                height: ret.restaurant.image.height || 0,
                url: ret.restaurant.image.url,
            },
        },
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
    };
}

export async function getAllAdminRestaurantUsers(pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllAdminRestaurantUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        restaurant: e.restaurant === null || e.restaurant === undefined ? null : {
            id: e.restaurant.id,
            name: e.restaurant.name,
            corporateName: e.restaurant.corporateName,
            documentNumber: e.restaurant.documentNumber,
            address: {
                zipcode: e.restaurant.address.zipcode,
                street: e.restaurant.address.street,
                streetNumber: e.restaurant.address.streetNumber,
                complementary: e.restaurant.address.complementary === null || e.restaurant.address.complementary === undefined ? null : e.restaurant.address.complementary,
                neighborhood: e.restaurant.address.neighborhood,
                city: e.restaurant.address.city,
                state: e.restaurant.address.state,
                countryCode: e.restaurant.address.countryCode,
            },
            createdAt: e.restaurant.createdAt === null || e.restaurant.createdAt === undefined ? null : new Date(e.restaurant.createdAt + "Z"),
            bankAccount: e.restaurant.bankAccount === null || e.restaurant.bankAccount === undefined ? null : {
                id: e.restaurant.bankAccount.id,
                bankName: e.restaurant.bankAccount.bankName,
                bankCode: e.restaurant.bankAccount.bankCode,
                agency: e.restaurant.bankAccount.agency,
                agencyDv: e.restaurant.bankAccount.agencyDv === null || e.restaurant.bankAccount.agencyDv === undefined ? null : e.restaurant.bankAccount.agencyDv,
                account: e.restaurant.bankAccount.account,
                accountDv: e.restaurant.bankAccount.accountDv === null || e.restaurant.bankAccount.accountDv === undefined ? null : e.restaurant.bankAccount.accountDv,
                type: e.restaurant.bankAccount.type,
                documentNumber: e.restaurant.bankAccount.documentNumber,
            },
            blockedAt: e.restaurant.blockedAt === null || e.restaurant.blockedAt === undefined ? null : new Date(parseInt(e.restaurant.blockedAt.split("-")[0], 10), parseInt(e.restaurant.blockedAt.split("-")[1], 10) - 1, parseInt(e.restaurant.blockedAt.split("-")[2], 10)),
            image: e.restaurant.image === null || e.restaurant.image === undefined ? null : {
                thumb: {
                    width: e.restaurant.image.thumb.width || 0,
                    height: e.restaurant.image.thumb.height || 0,
                    url: e.restaurant.image.thumb.url,
                },
                width: e.restaurant.image.width || 0,
                height: e.restaurant.image.height || 0,
                url: e.restaurant.image.url,
            },
        },
        createdAt: new Date(e.createdAt + "Z"),
        name: e.name,
        email: e.email,
    }));
}

export async function adminLogin(email: string, password: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        email: email,
        password: password,
    };
    const ret = await makeRequest({name: "adminLogin", args, progress});
    return {
        id: ret.id,
        restaurant: ret.restaurant === null || ret.restaurant === undefined ? null : {
            id: ret.restaurant.id,
            name: ret.restaurant.name,
            corporateName: ret.restaurant.corporateName,
            documentNumber: ret.restaurant.documentNumber,
            address: {
                zipcode: ret.restaurant.address.zipcode,
                street: ret.restaurant.address.street,
                streetNumber: ret.restaurant.address.streetNumber,
                complementary: ret.restaurant.address.complementary === null || ret.restaurant.address.complementary === undefined ? null : ret.restaurant.address.complementary,
                neighborhood: ret.restaurant.address.neighborhood,
                city: ret.restaurant.address.city,
                state: ret.restaurant.address.state,
                countryCode: ret.restaurant.address.countryCode,
            },
            createdAt: ret.restaurant.createdAt === null || ret.restaurant.createdAt === undefined ? null : new Date(ret.restaurant.createdAt + "Z"),
            bankAccount: ret.restaurant.bankAccount === null || ret.restaurant.bankAccount === undefined ? null : {
                id: ret.restaurant.bankAccount.id,
                bankName: ret.restaurant.bankAccount.bankName,
                bankCode: ret.restaurant.bankAccount.bankCode,
                agency: ret.restaurant.bankAccount.agency,
                agencyDv: ret.restaurant.bankAccount.agencyDv === null || ret.restaurant.bankAccount.agencyDv === undefined ? null : ret.restaurant.bankAccount.agencyDv,
                account: ret.restaurant.bankAccount.account,
                accountDv: ret.restaurant.bankAccount.accountDv === null || ret.restaurant.bankAccount.accountDv === undefined ? null : ret.restaurant.bankAccount.accountDv,
                type: ret.restaurant.bankAccount.type,
                documentNumber: ret.restaurant.bankAccount.documentNumber,
            },
            blockedAt: ret.restaurant.blockedAt === null || ret.restaurant.blockedAt === undefined ? null : new Date(parseInt(ret.restaurant.blockedAt.split("-")[0], 10), parseInt(ret.restaurant.blockedAt.split("-")[1], 10) - 1, parseInt(ret.restaurant.blockedAt.split("-")[2], 10)),
            image: ret.restaurant.image === null || ret.restaurant.image === undefined ? null : {
                thumb: {
                    width: ret.restaurant.image.thumb.width || 0,
                    height: ret.restaurant.image.thumb.height || 0,
                    url: ret.restaurant.image.thumb.url,
                },
                width: ret.restaurant.image.width || 0,
                height: ret.restaurant.image.height || 0,
                url: ret.restaurant.image.url,
            },
        },
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
    };
}

export async function logoutAdminUser(progress?: (progress: number) => void): Promise<void> {
    await makeRequest({name: "logoutAdminUser", args: {}, progress});
    return undefined;
}

export async function editAdminUser(id: string, editAdminUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
        editAdminUser: {
            name: editAdminUser.name,
            email: editAdminUser.email,
        },
    };
    const ret = await makeRequest({name: "editAdminUser", args, progress});
    return {
        id: ret.id,
        restaurant: ret.restaurant === null || ret.restaurant === undefined ? null : {
            id: ret.restaurant.id,
            name: ret.restaurant.name,
            corporateName: ret.restaurant.corporateName,
            documentNumber: ret.restaurant.documentNumber,
            address: {
                zipcode: ret.restaurant.address.zipcode,
                street: ret.restaurant.address.street,
                streetNumber: ret.restaurant.address.streetNumber,
                complementary: ret.restaurant.address.complementary === null || ret.restaurant.address.complementary === undefined ? null : ret.restaurant.address.complementary,
                neighborhood: ret.restaurant.address.neighborhood,
                city: ret.restaurant.address.city,
                state: ret.restaurant.address.state,
                countryCode: ret.restaurant.address.countryCode,
            },
            createdAt: ret.restaurant.createdAt === null || ret.restaurant.createdAt === undefined ? null : new Date(ret.restaurant.createdAt + "Z"),
            bankAccount: ret.restaurant.bankAccount === null || ret.restaurant.bankAccount === undefined ? null : {
                id: ret.restaurant.bankAccount.id,
                bankName: ret.restaurant.bankAccount.bankName,
                bankCode: ret.restaurant.bankAccount.bankCode,
                agency: ret.restaurant.bankAccount.agency,
                agencyDv: ret.restaurant.bankAccount.agencyDv === null || ret.restaurant.bankAccount.agencyDv === undefined ? null : ret.restaurant.bankAccount.agencyDv,
                account: ret.restaurant.bankAccount.account,
                accountDv: ret.restaurant.bankAccount.accountDv === null || ret.restaurant.bankAccount.accountDv === undefined ? null : ret.restaurant.bankAccount.accountDv,
                type: ret.restaurant.bankAccount.type,
                documentNumber: ret.restaurant.bankAccount.documentNumber,
            },
            blockedAt: ret.restaurant.blockedAt === null || ret.restaurant.blockedAt === undefined ? null : new Date(parseInt(ret.restaurant.blockedAt.split("-")[0], 10), parseInt(ret.restaurant.blockedAt.split("-")[1], 10) - 1, parseInt(ret.restaurant.blockedAt.split("-")[2], 10)),
            image: ret.restaurant.image === null || ret.restaurant.image === undefined ? null : {
                thumb: {
                    width: ret.restaurant.image.thumb.width || 0,
                    height: ret.restaurant.image.thumb.height || 0,
                    url: ret.restaurant.image.thumb.url,
                },
                width: ret.restaurant.image.width || 0,
                height: ret.restaurant.image.height || 0,
                url: ret.restaurant.image.url,
            },
        },
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
    };
}

export async function createAdminUser(newAdminUser: NewAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        newAdminUser: {
            restaurantId: newAdminUser.restaurantId === null || newAdminUser.restaurantId === undefined ? null : newAdminUser.restaurantId,
            password: newAdminUser.password,
            name: newAdminUser.name,
            email: newAdminUser.email,
        },
    };
    const ret = await makeRequest({name: "createAdminUser", args, progress});
    return {
        id: ret.id,
        restaurant: ret.restaurant === null || ret.restaurant === undefined ? null : {
            id: ret.restaurant.id,
            name: ret.restaurant.name,
            corporateName: ret.restaurant.corporateName,
            documentNumber: ret.restaurant.documentNumber,
            address: {
                zipcode: ret.restaurant.address.zipcode,
                street: ret.restaurant.address.street,
                streetNumber: ret.restaurant.address.streetNumber,
                complementary: ret.restaurant.address.complementary === null || ret.restaurant.address.complementary === undefined ? null : ret.restaurant.address.complementary,
                neighborhood: ret.restaurant.address.neighborhood,
                city: ret.restaurant.address.city,
                state: ret.restaurant.address.state,
                countryCode: ret.restaurant.address.countryCode,
            },
            createdAt: ret.restaurant.createdAt === null || ret.restaurant.createdAt === undefined ? null : new Date(ret.restaurant.createdAt + "Z"),
            bankAccount: ret.restaurant.bankAccount === null || ret.restaurant.bankAccount === undefined ? null : {
                id: ret.restaurant.bankAccount.id,
                bankName: ret.restaurant.bankAccount.bankName,
                bankCode: ret.restaurant.bankAccount.bankCode,
                agency: ret.restaurant.bankAccount.agency,
                agencyDv: ret.restaurant.bankAccount.agencyDv === null || ret.restaurant.bankAccount.agencyDv === undefined ? null : ret.restaurant.bankAccount.agencyDv,
                account: ret.restaurant.bankAccount.account,
                accountDv: ret.restaurant.bankAccount.accountDv === null || ret.restaurant.bankAccount.accountDv === undefined ? null : ret.restaurant.bankAccount.accountDv,
                type: ret.restaurant.bankAccount.type,
                documentNumber: ret.restaurant.bankAccount.documentNumber,
            },
            blockedAt: ret.restaurant.blockedAt === null || ret.restaurant.blockedAt === undefined ? null : new Date(parseInt(ret.restaurant.blockedAt.split("-")[0], 10), parseInt(ret.restaurant.blockedAt.split("-")[1], 10) - 1, parseInt(ret.restaurant.blockedAt.split("-")[2], 10)),
            image: ret.restaurant.image === null || ret.restaurant.image === undefined ? null : {
                thumb: {
                    width: ret.restaurant.image.thumb.width || 0,
                    height: ret.restaurant.image.thumb.height || 0,
                    url: ret.restaurant.image.thumb.url,
                },
                width: ret.restaurant.image.width || 0,
                height: ret.restaurant.image.height || 0,
                url: ret.restaurant.image.url,
            },
        },
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
    };
}

export async function deleteAdminUser(id: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteAdminUser", args, progress});
    return {
        id: ret.id,
        restaurant: ret.restaurant === null || ret.restaurant === undefined ? null : {
            id: ret.restaurant.id,
            name: ret.restaurant.name,
            corporateName: ret.restaurant.corporateName,
            documentNumber: ret.restaurant.documentNumber,
            address: {
                zipcode: ret.restaurant.address.zipcode,
                street: ret.restaurant.address.street,
                streetNumber: ret.restaurant.address.streetNumber,
                complementary: ret.restaurant.address.complementary === null || ret.restaurant.address.complementary === undefined ? null : ret.restaurant.address.complementary,
                neighborhood: ret.restaurant.address.neighborhood,
                city: ret.restaurant.address.city,
                state: ret.restaurant.address.state,
                countryCode: ret.restaurant.address.countryCode,
            },
            createdAt: ret.restaurant.createdAt === null || ret.restaurant.createdAt === undefined ? null : new Date(ret.restaurant.createdAt + "Z"),
            bankAccount: ret.restaurant.bankAccount === null || ret.restaurant.bankAccount === undefined ? null : {
                id: ret.restaurant.bankAccount.id,
                bankName: ret.restaurant.bankAccount.bankName,
                bankCode: ret.restaurant.bankAccount.bankCode,
                agency: ret.restaurant.bankAccount.agency,
                agencyDv: ret.restaurant.bankAccount.agencyDv === null || ret.restaurant.bankAccount.agencyDv === undefined ? null : ret.restaurant.bankAccount.agencyDv,
                account: ret.restaurant.bankAccount.account,
                accountDv: ret.restaurant.bankAccount.accountDv === null || ret.restaurant.bankAccount.accountDv === undefined ? null : ret.restaurant.bankAccount.accountDv,
                type: ret.restaurant.bankAccount.type,
                documentNumber: ret.restaurant.bankAccount.documentNumber,
            },
            blockedAt: ret.restaurant.blockedAt === null || ret.restaurant.blockedAt === undefined ? null : new Date(parseInt(ret.restaurant.blockedAt.split("-")[0], 10), parseInt(ret.restaurant.blockedAt.split("-")[1], 10) - 1, parseInt(ret.restaurant.blockedAt.split("-")[2], 10)),
            image: ret.restaurant.image === null || ret.restaurant.image === undefined ? null : {
                thumb: {
                    width: ret.restaurant.image.thumb.width || 0,
                    height: ret.restaurant.image.thumb.height || 0,
                    url: ret.restaurant.image.thumb.url,
                },
                width: ret.restaurant.image.width || 0,
                height: ret.restaurant.image.height || 0,
                url: ret.restaurant.image.url,
            },
        },
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
    };
}

export async function deleteUser(id: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        phone: ret.phone,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(ret.birthdate + "Z"),
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
        },
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getUser(id: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        phone: ret.phone,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(ret.birthdate + "Z"),
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
        },
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function editUser(id: string, editUser: EditUser, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
        editUser: {
            email: editUser.email,
            name: editUser.name,
            birthdate: editUser.birthdate === null || editUser.birthdate === undefined ? null : (typeof editUser.birthdate === "string" && (editUser.birthdate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editUser.birthdate as any).replace("Z", "") : editUser.birthdate.toISOString().replace("Z", "")),
            phone: editUser.phone,
            address: editUser.address === null || editUser.address === undefined ? null : {
                zipcode: editUser.address.zipcode,
                street: editUser.address.street,
                streetNumber: editUser.address.streetNumber,
                complementary: editUser.address.complementary === null || editUser.address.complementary === undefined ? null : editUser.address.complementary,
                neighborhood: editUser.address.neighborhood,
                city: editUser.address.city,
                state: editUser.address.state,
                countryCode: editUser.address.countryCode,
            },
            image: editUser.image === null || editUser.image === undefined ? null : {
                bytes: editUser.image.bytes === null || editUser.image.bytes === undefined ? null : editUser.image.bytes.toString("base64"),
                image: editUser.image.image === null || editUser.image.image === undefined ? null : {
                    thumb: {
                        width: editUser.image.image.thumb.width || 0,
                        height: editUser.image.image.thumb.height || 0,
                        url: editUser.image.image.thumb.url,
                    },
                    width: editUser.image.image.width || 0,
                    height: editUser.image.image.height || 0,
                    url: editUser.image.image.url,
                },
            },
            documentNumber: editUser.documentNumber === null || editUser.documentNumber === undefined ? null : editUser.documentNumber,
        },
    };
    const ret = await makeRequest({name: "editUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        phone: ret.phone,
        birthdate: ret.birthdate === null || ret.birthdate === undefined ? null : new Date(ret.birthdate + "Z"),
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
        },
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getAllUsers(pageOffSet: number, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
    };
    const ret = await makeRequest({name: "getAllUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        email: e.email,
        name: e.name,
        phone: e.phone,
        birthdate: e.birthdate === null || e.birthdate === undefined ? null : new Date(e.birthdate + "Z"),
        documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
        address: e.address === null || e.address === undefined ? null : {
            zipcode: e.address.zipcode,
            street: e.address.street,
            streetNumber: e.address.streetNumber,
            complementary: e.address.complementary === null || e.address.complementary === undefined ? null : e.address.complementary,
            neighborhood: e.address.neighborhood,
            city: e.address.city,
            state: e.address.state,
            countryCode: e.address.countryCode,
        },
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getAllConsumptionsByUser(pageOffSet: number, userId: string, progress?: (progress: number) => void): Promise<Consumption[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
        userId: userId,
    };
    const ret = await makeRequest({name: "getAllConsumptionsByUser", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        amount: e.amount || 0,
        createdAt: new Date(e.createdAt + "Z"),
        acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(e.acceptedAt + "Z"),
    }));
}

export async function getConsumption(id: string, progress?: (progress: number) => void): Promise<Consumption> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getConsumption", args, progress});
    return {
        id: ret.id,
        amount: ret.amount || 0,
        createdAt: new Date(ret.createdAt + "Z"),
        acceptedAt: ret.acceptedAt === null || ret.acceptedAt === undefined ? null : new Date(ret.acceptedAt + "Z"),
    };
}

export async function getAllConsumptionsByPurchasedProduct(pageOffSet: number, purchasedProductId: string, progress?: (progress: number) => void): Promise<Consumption[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
        purchasedProductId: purchasedProductId,
    };
    const ret = await makeRequest({name: "getAllConsumptionsByPurchasedProduct", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        amount: e.amount || 0,
        createdAt: new Date(e.createdAt + "Z"),
        acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(e.acceptedAt + "Z"),
    }));
}

export async function getAllAcceptedConsumptionsByRestaurant(pageOffSet: number, restaurantId: string, progress?: (progress: number) => void): Promise<Consumption[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
        restaurantId: restaurantId,
    };
    const ret = await makeRequest({name: "getAllAcceptedConsumptionsByRestaurant", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        amount: e.amount || 0,
        createdAt: new Date(e.createdAt + "Z"),
        acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(e.acceptedAt + "Z"),
    }));
}

export async function getAllPendingConsumptionsByRestaurant(pageOffSet: number, restaurantId: string, progress?: (progress: number) => void): Promise<Consumption[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
        restaurantId: restaurantId,
    };
    const ret = await makeRequest({name: "getAllPendingConsumptionsByRestaurant", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        amount: e.amount || 0,
        createdAt: new Date(e.createdAt + "Z"),
        acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(e.acceptedAt + "Z"),
    }));
}

export async function getConsumptionHistoryByPurchasedProduct(purchasedProductId: string, progress?: (progress: number) => void): Promise<Consumption[]> {
    const args = {
        purchasedProductId: purchasedProductId,
    };
    const ret = await makeRequest({name: "getConsumptionHistoryByPurchasedProduct", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        amount: e.amount || 0,
        createdAt: new Date(e.createdAt + "Z"),
        acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(e.acceptedAt + "Z"),
    }));
}

export async function approveConsumption(consumptionId: string, progress?: (progress: number) => void): Promise<Consumption> {
    const args = {
        consumptionId: consumptionId,
    };
    const ret = await makeRequest({name: "approveConsumption", args, progress});
    return {
        id: ret.id,
        amount: ret.amount || 0,
        createdAt: new Date(ret.createdAt + "Z"),
        acceptedAt: ret.acceptedAt === null || ret.acceptedAt === undefined ? null : new Date(ret.acceptedAt + "Z"),
    };
}

export async function deleteConsumption(consumptionId: string, progress?: (progress: number) => void): Promise<Consumption> {
    const args = {
        consumptionId: consumptionId,
    };
    const ret = await makeRequest({name: "deleteConsumption", args, progress});
    return {
        id: ret.id,
        amount: ret.amount || 0,
        createdAt: new Date(ret.createdAt + "Z"),
        acceptedAt: ret.acceptedAt === null || ret.acceptedAt === undefined ? null : new Date(ret.acceptedAt + "Z"),
    };
}

export async function getAllAcceptedConsumptionsForRestaurantAdmin(pageOffSet: number, progress?: (progress: number) => void): Promise<ConsumptionDetailed[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
    };
    const ret = await makeRequest({name: "getAllAcceptedConsumptionsForRestaurantAdmin", args, progress});
    return ret.map((e: any) => ({
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            phone: e.user.phone,
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
        },
        purchasedProduct: {
            id: e.purchasedProduct.id,
            product: {
                id: e.purchasedProduct.product.id,
                title: e.purchasedProduct.product.title,
            },
        },
        id: e.id,
        amount: e.amount || 0,
        createdAt: new Date(e.createdAt + "Z"),
        acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(e.acceptedAt + "Z"),
    }));
}

export async function getAllPendingConsumptionsForRestaurantAdmin(pageOffSet: number, progress?: (progress: number) => void): Promise<ConsumptionDetailed[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
    };
    const ret = await makeRequest({name: "getAllPendingConsumptionsForRestaurantAdmin", args, progress});
    return ret.map((e: any) => ({
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            phone: e.user.phone,
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
        },
        purchasedProduct: {
            id: e.purchasedProduct.id,
            product: {
                id: e.purchasedProduct.product.id,
                title: e.purchasedProduct.product.title,
            },
        },
        id: e.id,
        amount: e.amount || 0,
        createdAt: new Date(e.createdAt + "Z"),
        acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(e.acceptedAt + "Z"),
    }));
}

export async function getConsumptionForRestaurantAdminUser(id: string, progress?: (progress: number) => void): Promise<Consumption> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getConsumptionForRestaurantAdminUser", args, progress});
    return {
        id: ret.id,
        amount: ret.amount || 0,
        createdAt: new Date(ret.createdAt + "Z"),
        acceptedAt: ret.acceptedAt === null || ret.acceptedAt === undefined ? null : new Date(ret.acceptedAt + "Z"),
    };
}

export async function getConsumptionHistoryForRestaurantAdminUser(purchasedProductId: string, progress?: (progress: number) => void): Promise<Consumption[]> {
    const args = {
        purchasedProductId: purchasedProductId,
    };
    const ret = await makeRequest({name: "getConsumptionHistoryForRestaurantAdminUser", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        amount: e.amount || 0,
        createdAt: new Date(e.createdAt + "Z"),
        acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(e.acceptedAt + "Z"),
    }));
}

export async function getAllConsumptionsPaginatedForRestaurantAdminUser(pageOffSet: number, progress?: (progress: number) => void): Promise<Consumption[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
    };
    const ret = await makeRequest({name: "getAllConsumptionsPaginatedForRestaurantAdminUser", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        amount: e.amount || 0,
        createdAt: new Date(e.createdAt + "Z"),
        acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(e.acceptedAt + "Z"),
    }));
}

export async function createConsumptionForRestaurantAdminUser(purchasedProductId: string, amount: number, progress?: (progress: number) => void): Promise<Consumption> {
    const args = {
        purchasedProductId: purchasedProductId,
        amount: amount || 0,
    };
    const ret = await makeRequest({name: "createConsumptionForRestaurantAdminUser", args, progress});
    return {
        id: ret.id,
        amount: ret.amount || 0,
        createdAt: new Date(ret.createdAt + "Z"),
        acceptedAt: ret.acceptedAt === null || ret.acceptedAt === undefined ? null : new Date(ret.acceptedAt + "Z"),
    };
}

export async function getProduct(id: string, progress?: (progress: number) => void): Promise<Product> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getProduct", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        disabledAt: ret.disabledAt === null || ret.disabledAt === undefined ? null : new Date(parseInt(ret.disabledAt.split("-")[0], 10), parseInt(ret.disabledAt.split("-")[1], 10) - 1, parseInt(ret.disabledAt.split("-")[2], 10)),
        title: ret.title,
        description: ret.description,
        price: ret.price || 0,
        totalNumberOfDoses: ret.totalNumberOfDoses || 0,
    };
}

export async function getAllProducts(pageOffSet: number, progress?: (progress: number) => void): Promise<Product[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
    };
    const ret = await makeRequest({name: "getAllProducts", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        createdAt: new Date(e.createdAt + "Z"),
        disabledAt: e.disabledAt === null || e.disabledAt === undefined ? null : new Date(parseInt(e.disabledAt.split("-")[0], 10), parseInt(e.disabledAt.split("-")[1], 10) - 1, parseInt(e.disabledAt.split("-")[2], 10)),
        title: e.title,
        description: e.description,
        price: e.price || 0,
        totalNumberOfDoses: e.totalNumberOfDoses || 0,
    }));
}

export async function getAllProductsByRestaurant(pageOffSet: number, restaurantId: string, progress?: (progress: number) => void): Promise<Product[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
        restaurantId: restaurantId,
    };
    const ret = await makeRequest({name: "getAllProductsByRestaurant", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        createdAt: new Date(e.createdAt + "Z"),
        disabledAt: e.disabledAt === null || e.disabledAt === undefined ? null : new Date(parseInt(e.disabledAt.split("-")[0], 10), parseInt(e.disabledAt.split("-")[1], 10) - 1, parseInt(e.disabledAt.split("-")[2], 10)),
        title: e.title,
        description: e.description,
        price: e.price || 0,
        totalNumberOfDoses: e.totalNumberOfDoses || 0,
    }));
}

export async function getProductForRestaurantAdminUser(id: string, progress?: (progress: number) => void): Promise<Product> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getProductForRestaurantAdminUser", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        disabledAt: ret.disabledAt === null || ret.disabledAt === undefined ? null : new Date(parseInt(ret.disabledAt.split("-")[0], 10), parseInt(ret.disabledAt.split("-")[1], 10) - 1, parseInt(ret.disabledAt.split("-")[2], 10)),
        title: ret.title,
        description: ret.description,
        price: ret.price || 0,
        totalNumberOfDoses: ret.totalNumberOfDoses || 0,
    };
}

export async function getAllProductsForRestaurantAdminUser(pageOffSet: number, progress?: (progress: number) => void): Promise<Product[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
    };
    const ret = await makeRequest({name: "getAllProductsForRestaurantAdminUser", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        createdAt: new Date(e.createdAt + "Z"),
        disabledAt: e.disabledAt === null || e.disabledAt === undefined ? null : new Date(parseInt(e.disabledAt.split("-")[0], 10), parseInt(e.disabledAt.split("-")[1], 10) - 1, parseInt(e.disabledAt.split("-")[2], 10)),
        title: e.title,
        description: e.description,
        price: e.price || 0,
        totalNumberOfDoses: e.totalNumberOfDoses || 0,
    }));
}

export async function deleteProduct(id: string, progress?: (progress: number) => void): Promise<Product> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteProduct", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        disabledAt: ret.disabledAt === null || ret.disabledAt === undefined ? null : new Date(parseInt(ret.disabledAt.split("-")[0], 10), parseInt(ret.disabledAt.split("-")[1], 10) - 1, parseInt(ret.disabledAt.split("-")[2], 10)),
        title: ret.title,
        description: ret.description,
        price: ret.price || 0,
        totalNumberOfDoses: ret.totalNumberOfDoses || 0,
    };
}

export async function createProduct(newProduct: NewProduct, restaurantId: string, progress?: (progress: number) => void): Promise<Product> {
    const args = {
        newProduct: {
            image: newProduct.image === null || newProduct.image === undefined ? null : {
                bytes: newProduct.image.bytes === null || newProduct.image.bytes === undefined ? null : newProduct.image.bytes.toString("base64"),
                image: newProduct.image.image === null || newProduct.image.image === undefined ? null : {
                    thumb: {
                        width: newProduct.image.image.thumb.width || 0,
                        height: newProduct.image.image.thumb.height || 0,
                        url: newProduct.image.image.thumb.url,
                    },
                    width: newProduct.image.image.width || 0,
                    height: newProduct.image.image.height || 0,
                    url: newProduct.image.image.url,
                },
            },
            title: newProduct.title,
            description: newProduct.description,
            price: newProduct.price || 0,
            totalNumberOfDoses: newProduct.totalNumberOfDoses || 0,
        },
        restaurantId: restaurantId,
    };
    const ret = await makeRequest({name: "createProduct", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        disabledAt: ret.disabledAt === null || ret.disabledAt === undefined ? null : new Date(parseInt(ret.disabledAt.split("-")[0], 10), parseInt(ret.disabledAt.split("-")[1], 10) - 1, parseInt(ret.disabledAt.split("-")[2], 10)),
        title: ret.title,
        description: ret.description,
        price: ret.price || 0,
        totalNumberOfDoses: ret.totalNumberOfDoses || 0,
    };
}

export async function createProductForRestaurantUser(newProduct: NewProduct, progress?: (progress: number) => void): Promise<Product> {
    const args = {
        newProduct: {
            image: newProduct.image === null || newProduct.image === undefined ? null : {
                bytes: newProduct.image.bytes === null || newProduct.image.bytes === undefined ? null : newProduct.image.bytes.toString("base64"),
                image: newProduct.image.image === null || newProduct.image.image === undefined ? null : {
                    thumb: {
                        width: newProduct.image.image.thumb.width || 0,
                        height: newProduct.image.image.thumb.height || 0,
                        url: newProduct.image.image.thumb.url,
                    },
                    width: newProduct.image.image.width || 0,
                    height: newProduct.image.image.height || 0,
                    url: newProduct.image.image.url,
                },
            },
            title: newProduct.title,
            description: newProduct.description,
            price: newProduct.price || 0,
            totalNumberOfDoses: newProduct.totalNumberOfDoses || 0,
        },
    };
    const ret = await makeRequest({name: "createProductForRestaurantUser", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        disabledAt: ret.disabledAt === null || ret.disabledAt === undefined ? null : new Date(parseInt(ret.disabledAt.split("-")[0], 10), parseInt(ret.disabledAt.split("-")[1], 10) - 1, parseInt(ret.disabledAt.split("-")[2], 10)),
        title: ret.title,
        description: ret.description,
        price: ret.price || 0,
        totalNumberOfDoses: ret.totalNumberOfDoses || 0,
    };
}

export async function editProduct(id: string, newProduct: NewProduct, progress?: (progress: number) => void): Promise<Product> {
    const args = {
        id: id,
        newProduct: {
            image: newProduct.image === null || newProduct.image === undefined ? null : {
                bytes: newProduct.image.bytes === null || newProduct.image.bytes === undefined ? null : newProduct.image.bytes.toString("base64"),
                image: newProduct.image.image === null || newProduct.image.image === undefined ? null : {
                    thumb: {
                        width: newProduct.image.image.thumb.width || 0,
                        height: newProduct.image.image.thumb.height || 0,
                        url: newProduct.image.image.thumb.url,
                    },
                    width: newProduct.image.image.width || 0,
                    height: newProduct.image.image.height || 0,
                    url: newProduct.image.image.url,
                },
            },
            title: newProduct.title,
            description: newProduct.description,
            price: newProduct.price || 0,
            totalNumberOfDoses: newProduct.totalNumberOfDoses || 0,
        },
    };
    const ret = await makeRequest({name: "editProduct", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        disabledAt: ret.disabledAt === null || ret.disabledAt === undefined ? null : new Date(parseInt(ret.disabledAt.split("-")[0], 10), parseInt(ret.disabledAt.split("-")[1], 10) - 1, parseInt(ret.disabledAt.split("-")[2], 10)),
        title: ret.title,
        description: ret.description,
        price: ret.price || 0,
        totalNumberOfDoses: ret.totalNumberOfDoses || 0,
    };
}

export async function changeProductDisableStatus(id: string, willBeBlocked: boolean, progress?: (progress: number) => void): Promise<Product> {
    const args = {
        id: id,
        willBeBlocked: !!willBeBlocked,
    };
    const ret = await makeRequest({name: "changeProductDisableStatus", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        disabledAt: ret.disabledAt === null || ret.disabledAt === undefined ? null : new Date(parseInt(ret.disabledAt.split("-")[0], 10), parseInt(ret.disabledAt.split("-")[1], 10) - 1, parseInt(ret.disabledAt.split("-")[2], 10)),
        title: ret.title,
        description: ret.description,
        price: ret.price || 0,
        totalNumberOfDoses: ret.totalNumberOfDoses || 0,
    };
}

export async function changeProductDisableStatusForRestaurantAdminUser(id: string, willBeDisabled: boolean, progress?: (progress: number) => void): Promise<Product> {
    const args = {
        id: id,
        willBeDisabled: !!willBeDisabled,
    };
    const ret = await makeRequest({name: "changeProductDisableStatusForRestaurantAdminUser", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        disabledAt: ret.disabledAt === null || ret.disabledAt === undefined ? null : new Date(parseInt(ret.disabledAt.split("-")[0], 10), parseInt(ret.disabledAt.split("-")[1], 10) - 1, parseInt(ret.disabledAt.split("-")[2], 10)),
        title: ret.title,
        description: ret.description,
        price: ret.price || 0,
        totalNumberOfDoses: ret.totalNumberOfDoses || 0,
    };
}

export async function editProductFoRestaurantAdminUser(id: string, newProduct: NewProduct, progress?: (progress: number) => void): Promise<Product> {
    const args = {
        id: id,
        newProduct: {
            image: newProduct.image === null || newProduct.image === undefined ? null : {
                bytes: newProduct.image.bytes === null || newProduct.image.bytes === undefined ? null : newProduct.image.bytes.toString("base64"),
                image: newProduct.image.image === null || newProduct.image.image === undefined ? null : {
                    thumb: {
                        width: newProduct.image.image.thumb.width || 0,
                        height: newProduct.image.image.thumb.height || 0,
                        url: newProduct.image.image.thumb.url,
                    },
                    width: newProduct.image.image.width || 0,
                    height: newProduct.image.image.height || 0,
                    url: newProduct.image.image.url,
                },
            },
            title: newProduct.title,
            description: newProduct.description,
            price: newProduct.price || 0,
            totalNumberOfDoses: newProduct.totalNumberOfDoses || 0,
        },
    };
    const ret = await makeRequest({name: "editProductFoRestaurantAdminUser", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        disabledAt: ret.disabledAt === null || ret.disabledAt === undefined ? null : new Date(parseInt(ret.disabledAt.split("-")[0], 10), parseInt(ret.disabledAt.split("-")[1], 10) - 1, parseInt(ret.disabledAt.split("-")[2], 10)),
        title: ret.title,
        description: ret.description,
        price: ret.price || 0,
        totalNumberOfDoses: ret.totalNumberOfDoses || 0,
    };
}

export async function deleteProductForRestaurantUser(id: string, progress?: (progress: number) => void): Promise<Product> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteProductForRestaurantUser", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        disabledAt: ret.disabledAt === null || ret.disabledAt === undefined ? null : new Date(parseInt(ret.disabledAt.split("-")[0], 10), parseInt(ret.disabledAt.split("-")[1], 10) - 1, parseInt(ret.disabledAt.split("-")[2], 10)),
        title: ret.title,
        description: ret.description,
        price: ret.price || 0,
        totalNumberOfDoses: ret.totalNumberOfDoses || 0,
    };
}

export async function getPurchasedProduct(id: string, progress?: (progress: number) => void): Promise<PurchasedProduct> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getPurchasedProduct", args, progress});
    return {
        paymentMethod: ret.paymentMethod,
        product: {
            id: ret.product.id,
            image: ret.product.image === null || ret.product.image === undefined ? null : {
                thumb: {
                    width: ret.product.image.thumb.width || 0,
                    height: ret.product.image.thumb.height || 0,
                    url: ret.product.image.thumb.url,
                },
                width: ret.product.image.width || 0,
                height: ret.product.image.height || 0,
                url: ret.product.image.url,
            },
            createdAt: new Date(ret.product.createdAt + "Z"),
            disabledAt: ret.product.disabledAt === null || ret.product.disabledAt === undefined ? null : new Date(parseInt(ret.product.disabledAt.split("-")[0], 10), parseInt(ret.product.disabledAt.split("-")[1], 10) - 1, parseInt(ret.product.disabledAt.split("-")[2], 10)),
            title: ret.product.title,
            description: ret.product.description,
            price: ret.product.price || 0,
            totalNumberOfDoses: ret.product.totalNumberOfDoses || 0,
        },
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            phone: ret.user.phone,
            birthdate: ret.user.birthdate === null || ret.user.birthdate === undefined ? null : new Date(ret.user.birthdate + "Z"),
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            address: ret.user.address === null || ret.user.address === undefined ? null : {
                zipcode: ret.user.address.zipcode,
                street: ret.user.address.street,
                streetNumber: ret.user.address.streetNumber,
                complementary: ret.user.address.complementary === null || ret.user.address.complementary === undefined ? null : ret.user.address.complementary,
                neighborhood: ret.user.address.neighborhood,
                city: ret.user.address.city,
                state: ret.user.address.state,
                countryCode: ret.user.address.countryCode,
            },
            image: ret.user.image === null || ret.user.image === undefined ? null : {
                thumb: {
                    width: ret.user.image.thumb.width || 0,
                    height: ret.user.image.thumb.height || 0,
                    url: ret.user.image.thumb.url,
                },
                width: ret.user.image.width || 0,
                height: ret.user.image.height || 0,
                url: ret.user.image.url,
            },
            createdAt: new Date(ret.user.createdAt + "Z"),
        },
        id: ret.id,
        status: ret.status,
        purchasedAt: new Date(ret.purchasedAt + "Z"),
        consumedDoses: ret.consumedDoses || 0,
        totalDoses: ret.totalDoses || 0,
    };
}

export async function getAllPurchasedProducts(pageOffset: number, progress?: (progress: number) => void): Promise<PurchasedProduct[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllPurchasedProducts", args, progress});
    return ret.map((e: any) => ({
        paymentMethod: e.paymentMethod,
        product: {
            id: e.product.id,
            image: e.product.image === null || e.product.image === undefined ? null : {
                thumb: {
                    width: e.product.image.thumb.width || 0,
                    height: e.product.image.thumb.height || 0,
                    url: e.product.image.thumb.url,
                },
                width: e.product.image.width || 0,
                height: e.product.image.height || 0,
                url: e.product.image.url,
            },
            createdAt: new Date(e.product.createdAt + "Z"),
            disabledAt: e.product.disabledAt === null || e.product.disabledAt === undefined ? null : new Date(parseInt(e.product.disabledAt.split("-")[0], 10), parseInt(e.product.disabledAt.split("-")[1], 10) - 1, parseInt(e.product.disabledAt.split("-")[2], 10)),
            title: e.product.title,
            description: e.product.description,
            price: e.product.price || 0,
            totalNumberOfDoses: e.product.totalNumberOfDoses || 0,
        },
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            phone: e.user.phone,
            birthdate: e.user.birthdate === null || e.user.birthdate === undefined ? null : new Date(e.user.birthdate + "Z"),
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
            address: e.user.address === null || e.user.address === undefined ? null : {
                zipcode: e.user.address.zipcode,
                street: e.user.address.street,
                streetNumber: e.user.address.streetNumber,
                complementary: e.user.address.complementary === null || e.user.address.complementary === undefined ? null : e.user.address.complementary,
                neighborhood: e.user.address.neighborhood,
                city: e.user.address.city,
                state: e.user.address.state,
                countryCode: e.user.address.countryCode,
            },
            image: e.user.image === null || e.user.image === undefined ? null : {
                thumb: {
                    width: e.user.image.thumb.width || 0,
                    height: e.user.image.thumb.height || 0,
                    url: e.user.image.thumb.url,
                },
                width: e.user.image.width || 0,
                height: e.user.image.height || 0,
                url: e.user.image.url,
            },
            createdAt: new Date(e.user.createdAt + "Z"),
        },
        id: e.id,
        status: e.status,
        purchasedAt: new Date(e.purchasedAt + "Z"),
        consumedDoses: e.consumedDoses || 0,
        totalDoses: e.totalDoses || 0,
    }));
}

export async function getAllPurchasedProductsByUser(pageOffset: number, userId: string, progress?: (progress: number) => void): Promise<PurchasedProductWithoutUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
        userId: userId,
    };
    const ret = await makeRequest({name: "getAllPurchasedProductsByUser", args, progress});
    return ret.map((e: any) => ({
        product: {
            id: e.product.id,
            image: e.product.image === null || e.product.image === undefined ? null : {
                thumb: {
                    width: e.product.image.thumb.width || 0,
                    height: e.product.image.thumb.height || 0,
                    url: e.product.image.thumb.url,
                },
                width: e.product.image.width || 0,
                height: e.product.image.height || 0,
                url: e.product.image.url,
            },
            createdAt: new Date(e.product.createdAt + "Z"),
            disabledAt: e.product.disabledAt === null || e.product.disabledAt === undefined ? null : new Date(parseInt(e.product.disabledAt.split("-")[0], 10), parseInt(e.product.disabledAt.split("-")[1], 10) - 1, parseInt(e.product.disabledAt.split("-")[2], 10)),
            title: e.product.title,
            description: e.product.description,
            price: e.product.price || 0,
            totalNumberOfDoses: e.product.totalNumberOfDoses || 0,
        },
        id: e.id,
        paymentMethod: e.paymentMethod,
        status: e.status,
        purchasedAt: new Date(e.purchasedAt + "Z"),
        consumedDoses: e.consumedDoses || 0,
        totalDoses: e.totalDoses || 0,
    }));
}

export async function getAllPurchasedProductsForRestaurantAdminUser(pageOffset: number, progress?: (progress: number) => void): Promise<PurchasedProduct[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllPurchasedProductsForRestaurantAdminUser", args, progress});
    return ret.map((e: any) => ({
        paymentMethod: e.paymentMethod,
        product: {
            id: e.product.id,
            image: e.product.image === null || e.product.image === undefined ? null : {
                thumb: {
                    width: e.product.image.thumb.width || 0,
                    height: e.product.image.thumb.height || 0,
                    url: e.product.image.thumb.url,
                },
                width: e.product.image.width || 0,
                height: e.product.image.height || 0,
                url: e.product.image.url,
            },
            createdAt: new Date(e.product.createdAt + "Z"),
            disabledAt: e.product.disabledAt === null || e.product.disabledAt === undefined ? null : new Date(parseInt(e.product.disabledAt.split("-")[0], 10), parseInt(e.product.disabledAt.split("-")[1], 10) - 1, parseInt(e.product.disabledAt.split("-")[2], 10)),
            title: e.product.title,
            description: e.product.description,
            price: e.product.price || 0,
            totalNumberOfDoses: e.product.totalNumberOfDoses || 0,
        },
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            phone: e.user.phone,
            birthdate: e.user.birthdate === null || e.user.birthdate === undefined ? null : new Date(e.user.birthdate + "Z"),
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
            address: e.user.address === null || e.user.address === undefined ? null : {
                zipcode: e.user.address.zipcode,
                street: e.user.address.street,
                streetNumber: e.user.address.streetNumber,
                complementary: e.user.address.complementary === null || e.user.address.complementary === undefined ? null : e.user.address.complementary,
                neighborhood: e.user.address.neighborhood,
                city: e.user.address.city,
                state: e.user.address.state,
                countryCode: e.user.address.countryCode,
            },
            image: e.user.image === null || e.user.image === undefined ? null : {
                thumb: {
                    width: e.user.image.thumb.width || 0,
                    height: e.user.image.thumb.height || 0,
                    url: e.user.image.thumb.url,
                },
                width: e.user.image.width || 0,
                height: e.user.image.height || 0,
                url: e.user.image.url,
            },
            createdAt: new Date(e.user.createdAt + "Z"),
        },
        id: e.id,
        status: e.status,
        purchasedAt: new Date(e.purchasedAt + "Z"),
        consumedDoses: e.consumedDoses || 0,
        totalDoses: e.totalDoses || 0,
    }));
}

export async function getPurchasedProductForRestaurantAdminUser(id: string, progress?: (progress: number) => void): Promise<PurchasedProduct> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getPurchasedProductForRestaurantAdminUser", args, progress});
    return {
        paymentMethod: ret.paymentMethod,
        product: {
            id: ret.product.id,
            image: ret.product.image === null || ret.product.image === undefined ? null : {
                thumb: {
                    width: ret.product.image.thumb.width || 0,
                    height: ret.product.image.thumb.height || 0,
                    url: ret.product.image.thumb.url,
                },
                width: ret.product.image.width || 0,
                height: ret.product.image.height || 0,
                url: ret.product.image.url,
            },
            createdAt: new Date(ret.product.createdAt + "Z"),
            disabledAt: ret.product.disabledAt === null || ret.product.disabledAt === undefined ? null : new Date(parseInt(ret.product.disabledAt.split("-")[0], 10), parseInt(ret.product.disabledAt.split("-")[1], 10) - 1, parseInt(ret.product.disabledAt.split("-")[2], 10)),
            title: ret.product.title,
            description: ret.product.description,
            price: ret.product.price || 0,
            totalNumberOfDoses: ret.product.totalNumberOfDoses || 0,
        },
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            phone: ret.user.phone,
            birthdate: ret.user.birthdate === null || ret.user.birthdate === undefined ? null : new Date(ret.user.birthdate + "Z"),
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            address: ret.user.address === null || ret.user.address === undefined ? null : {
                zipcode: ret.user.address.zipcode,
                street: ret.user.address.street,
                streetNumber: ret.user.address.streetNumber,
                complementary: ret.user.address.complementary === null || ret.user.address.complementary === undefined ? null : ret.user.address.complementary,
                neighborhood: ret.user.address.neighborhood,
                city: ret.user.address.city,
                state: ret.user.address.state,
                countryCode: ret.user.address.countryCode,
            },
            image: ret.user.image === null || ret.user.image === undefined ? null : {
                thumb: {
                    width: ret.user.image.thumb.width || 0,
                    height: ret.user.image.thumb.height || 0,
                    url: ret.user.image.thumb.url,
                },
                width: ret.user.image.width || 0,
                height: ret.user.image.height || 0,
                url: ret.user.image.url,
            },
            createdAt: new Date(ret.user.createdAt + "Z"),
        },
        id: ret.id,
        status: ret.status,
        purchasedAt: new Date(ret.purchasedAt + "Z"),
        consumedDoses: ret.consumedDoses || 0,
        totalDoses: ret.totalDoses || 0,
    };
}

export async function getAllPurchasedProductsByUserFilterOptionsForRestaurantAdminUser(pageOffset: number, userFilterOptions: UserFilterOptions | null, progress?: (progress: number) => void): Promise<PurchasedProduct[]> {
    const args = {
        pageOffset: pageOffset || 0,
        userFilterOptions: userFilterOptions === null || userFilterOptions === undefined ? null : {
            name: userFilterOptions.name === null || userFilterOptions.name === undefined ? null : userFilterOptions.name,
            documentNumber: userFilterOptions.documentNumber === null || userFilterOptions.documentNumber === undefined ? null : userFilterOptions.documentNumber,
        },
    };
    const ret = await makeRequest({name: "getAllPurchasedProductsByUserFilterOptionsForRestaurantAdminUser", args, progress});
    return ret.map((e: any) => ({
        paymentMethod: e.paymentMethod,
        product: {
            id: e.product.id,
            image: e.product.image === null || e.product.image === undefined ? null : {
                thumb: {
                    width: e.product.image.thumb.width || 0,
                    height: e.product.image.thumb.height || 0,
                    url: e.product.image.thumb.url,
                },
                width: e.product.image.width || 0,
                height: e.product.image.height || 0,
                url: e.product.image.url,
            },
            createdAt: new Date(e.product.createdAt + "Z"),
            disabledAt: e.product.disabledAt === null || e.product.disabledAt === undefined ? null : new Date(parseInt(e.product.disabledAt.split("-")[0], 10), parseInt(e.product.disabledAt.split("-")[1], 10) - 1, parseInt(e.product.disabledAt.split("-")[2], 10)),
            title: e.product.title,
            description: e.product.description,
            price: e.product.price || 0,
            totalNumberOfDoses: e.product.totalNumberOfDoses || 0,
        },
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            phone: e.user.phone,
            birthdate: e.user.birthdate === null || e.user.birthdate === undefined ? null : new Date(e.user.birthdate + "Z"),
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
            address: e.user.address === null || e.user.address === undefined ? null : {
                zipcode: e.user.address.zipcode,
                street: e.user.address.street,
                streetNumber: e.user.address.streetNumber,
                complementary: e.user.address.complementary === null || e.user.address.complementary === undefined ? null : e.user.address.complementary,
                neighborhood: e.user.address.neighborhood,
                city: e.user.address.city,
                state: e.user.address.state,
                countryCode: e.user.address.countryCode,
            },
            image: e.user.image === null || e.user.image === undefined ? null : {
                thumb: {
                    width: e.user.image.thumb.width || 0,
                    height: e.user.image.thumb.height || 0,
                    url: e.user.image.thumb.url,
                },
                width: e.user.image.width || 0,
                height: e.user.image.height || 0,
                url: e.user.image.url,
            },
            createdAt: new Date(e.user.createdAt + "Z"),
        },
        id: e.id,
        status: e.status,
        purchasedAt: new Date(e.purchasedAt + "Z"),
        consumedDoses: e.consumedDoses || 0,
        totalDoses: e.totalDoses || 0,
    }));
}

export async function sendRequestResetPassword(email: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        email: email,
    };
    await makeRequest({name: "sendRequestResetPassword", args, progress});
    return undefined;
}

export async function validateToken(token: string, progress?: (progress: number) => void): Promise<boolean> {
    const args = {
        token: token,
    };
    const ret = await makeRequest({name: "validateToken", args, progress});
    return !!ret;
}

export async function resetPassword(token: string, newPassword: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
        newPassword: newPassword,
    };
    await makeRequest({name: "resetPassword", args, progress});
    return undefined;
}

export async function ping(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "ping", args: {}, progress});
    return ret;
}

export async function setPushToken(token: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
    };
    await makeRequest({name: "setPushToken", args, progress});
    return undefined;
}

//////////////////////////////////////////////////////

let fallbackDeviceId: string | null = null;

function setDeviceId(deviceId: string): void {
    fallbackDeviceId = deviceId;
    try {
        localStorage.setItem("deviceId", deviceId);
    } catch (e) {}
}

function getDeviceId(): string | null {
    try {
        return localStorage.getItem("deviceId") || fallbackDeviceId;
    } catch (e) {}

    return fallbackDeviceId;
}

async function device(): Promise<any> {
    const parser = new UAParser();
    parser.setUA(navigator.userAgent);
    const agent = parser.getResult();
    const me = document.currentScript as HTMLScriptElement;
    const device: any = {
            type: "web",
            platform: {
                browser: agent.browser.name,
                browserVersion: agent.browser.version,
                os: agent.os.name,
                osVersion: agent.os.version,
            },
            screen: {
                width: screen.width,
                height: screen.height,
            },
            version: me ? me.src : "",
            language: navigator.language,
    };

    const deviceId = getDeviceId();
    if (deviceId)
        device.id = deviceId;

    return device;
}

function randomBytesHex(len: number): string {
    let hex = "";
    for (let i = 0; i < 2 * len; ++i) {
        hex += "0123456789abcdef"[Math.floor(Math.random() * 16)];
    }

    return hex;
}

export interface ListenerTypes {
    fail: (e: Error, name: string, args: any) => void;
    fatal: (e: Error, name: string, args: any) => void;
    success: (res: string, name: string, args: any) => void;
}

// tslint:disable-next-line: ban-types
type HookArray = Function[];
export type Listenables = keyof ListenerTypes;
export type ListenersDict = { [k in Listenables]: Array<ListenerTypes[k]> };

const listenersDict: ListenersDict = {
    fail: [],
    fatal: [],
    success: [],
};

export function addEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listeners.push(hook);
}

export function removeEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listenersDict[listenable] = listeners.filter((h) => h !== hook) as any;
}

async function makeRequest({name, args, progress}: {name: string, args: any, progress?: (progress: number) => void}): Promise<any> {
    const deviceData = await device();
    return new Promise<any>((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open(
            "POST",
            `${baseUrl.startsWith("http") || baseUrl.startsWith("localhost") ?
                "" :
                "https://"
            }${baseUrl}/${name}`,
        );

        const body = {
            id: randomBytesHex(8),
            device: deviceData,
            name: name,
            args: args,
        };

        function roughSizeOfObject(object: any): number {
            const objectList: any = [];
            const stack: any = [ object ];
            let bytes = 0;

            while (stack.length) {
                const value = stack.pop();
                if (typeof value === "boolean") {
                    bytes += 4;
                } else if (typeof value === "string") {
                    bytes += value.length * 2;
                } else if (typeof value === "number") {
                    bytes += 8;
                } else if (
                    typeof value === "object"
                    && objectList.indexOf(value) === -1
                ) {
                    objectList.push(value);
                    for (const i in value) {
                        stack.push(value[i]);
                    }
                }
            }

            return bytes;
        }

        req.upload.onprogress = (event: ProgressEvent) => {
            if (event.lengthComputable && progress) {
                progress(Math.ceil(((event.loaded) / event.total) * 100));
            }
        };

        req.onreadystatechange = () => {
            if (req.readyState !== 4) return;
            try {
                const response = JSON.parse(req.responseText);

                try {
                    setDeviceId(response.deviceId);

                    if (response.ok) {
                        resolve(response.result);
                        listenersDict["success"].forEach((hook) => hook(response.result, name, args));
                    } else {
                        const error = typeof response.error === "object" ?
                            response.error :
                            { type: "Fatal", message: response.toString() };

                        reject(error);

                        listenersDict["fail"].forEach((hook) => hook(error, name, args));
                    }
                } catch (e) {
                    console.error(e);
                    reject({type: "Fatal", message: `[${name}] ${e.toString()}`});

                    listenersDict["fatal"].forEach((hook) => hook(e, name, args));
                }
            } catch (e) {
                console.error(e);
                reject({ type: "BadFormattedResponse", message: `Response couldn't be parsed as JSON (${req.responseText}):\n${e.toString()}` });
                listenersDict["fatal"].forEach((hook) => hook(e, name, args));
            }
        };

        req.send(JSON.stringify(body));
    });
}
